import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from 'rebass';
import { Input } from '@rebass/forms';
import { Activity } from 'types/activity';
import FormInput from './Inputs/FormInput';
import ShoeSelectInput from './Inputs/ShoeSelectInput';
import IntervalWorkoutInputs from './Groups/IntervalWorkoutInputs';
import { ActivityUpdateInput, WorkoutType } from 'generated/gql';

interface ActivityEditFormProps {
  activity: Activity;
  onSubmit: (values: Activity) => void;
}

const ActivityEditForm: FunctionComponent<ActivityEditFormProps> = ({ activity, onSubmit }) => {
  const formMethods = useForm({ defaultValues: mapToFormValues(activity) });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box mb={[2, 4, 4]}>
          <FormInput type="text" name="title" label="Title" placeholder="Activity title" required />
        </Box>
        <Box mb={[2, 4, 4]}>
          <FormInput type="textarea" name="description" label="Description" placeholder="How did you feel?" />
        </Box>
        <ShoeSelectInput />
        <Box mb={[2, 4, 4]}>
          <IntervalWorkoutInputs name="intervals" workoutType={activity.workoutType || WorkoutType.Default} />
        </Box>
        <Button as={Input} variant="primary" type="submit" />
      </form>
    </FormProvider>
  );
};

function mapToFormValues(activity: Activity): ActivityUpdateInput {
  return {
    id: activity.id,
    title: activity.title,
    description: activity.description,
    shoe: activity.shoe,
    intervals: activity.intervals,
  };
}

export default ActivityEditForm;
