/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Button, Flex, Box } from 'rebass';
import { Link as StyledLink } from 'rebass';
import ToggleMode from './ToggleMode';
import { logout, login } from '../services/auth';
import { useIsAuthenticatedQuery } from 'generated/gql';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const { data, error, loading } = useIsAuthenticatedQuery();
  const isAuthenticated = !error && !loading && data?.isAuthenticated;

  return (
    <Flex
      style={{ gridArea: 'nav' }}
      py={2}
      mx={'auto'}
      width={'100%'}
      maxWidth={1280}
      as="nav"
      justifyContent="space-between"
      alignItems="center"
    >
      <StyledLink
        fontSize={[4, 3, 4, 4]}
        p={0}
        as={Link}
        // @ts-ignore
        to="/activities"
        title="Landing"
        sx={{ fontFamily: 'Gimbo Ultra', letterSpacing: '1px', position: 'relative' }}
      >
        <Box>CASSIDY</Box>
      </StyledLink>

      <Box mx={'auto'} />
      {isAuthenticated && (
        // @ts-ignore
        <StyledLink as={Link} fontSize={3} to="/activities" title="Log">
          Running log
        </StyledLink>
      )}
      {isAuthenticated && (
        <Link to="/activity/new">
          <Button name="New workout" mx={3}>
            New workout
          </Button>
        </Link>
      )}
      {isAuthenticated ? (
        <Button name="Log out" variant="outline" onClick={logout}>
          Logout
        </Button>
      ) : (
        <Button name="Login" variant="outline" onClick={login}>
          Login
        </Button>
      )}
      <Box ml={3}>
        <ToggleMode />
      </Box>
    </Flex>
  );
};

export default NavBar;
