import { v4 as guid } from 'uuid';

const clientId = '8763';
const cassidyAppUrl = process.env.REACT_APP_APPLICATION_URL || 'https://runcassidy.com';
const stravaAuthUrl = 'https://www.strava.com/oauth/authorize';
const STRAVA_OAUTH_STATE_PARAM = 'STRAVA_OAUTH_STATE_PARAM';

export const makeStravaAuthenticationUrl = (): string => {
  // make a random guid and store it so we can verify as part of the callback
  const state = guid();
  localStorage.setItem(STRAVA_OAUTH_STATE_PARAM, state);

  const params: Record<string, string> = {
    client_id: clientId,
    redirect_uri: `${cassidyAppUrl}/connect/strava`,
    response_type: 'code',
    scope: 'activity:read_all,profile:read_all', // TODO: more scopes!
    state,
  };

  const url = new URL(stravaAuthUrl);

  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  return url.toString();
};

export const isExpectedOAuthState = (state: string): boolean => {
  const expectedState = localStorage.getItem(STRAVA_OAUTH_STATE_PARAM);
  // clean up!
  localStorage.removeItem(STRAVA_OAUTH_STATE_PARAM);
  return state === expectedState;
};
