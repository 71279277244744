import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AddShoePage from '../pages/AddShoe';
import AddActivityPage from '../pages/AddActivity';
import LogPage from '../pages/Log';
import Home from '../pages/Home';
import PageNotFound from '../pages/PageNotFound';
import PostAuthPage from '../pages/PostAuth';
import ActivityPage from '../pages/Activity';
import AuthContainer from './AuthContainer';
import ConnectStrava from '../pages/ConnectStrava';
import LoginPage from 'pages/Login';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* There are essentially two entry points into the app:
         * /postAuth handles login responses from auth0
         * / tries to get auth info from an exisiting token
         */}
        <Route exact path="/postAuth" component={PostAuthPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/">
          <AuthContainer>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/log" render={() => <Redirect to="/activities" />} />
              <ProtectedRoute path="/connect/strava" component={ConnectStrava} />
              <ProtectedRoute exact path="/activities" component={LogPage} />
              <ProtectedRoute path="/gear/new" component={AddShoePage} />
              <ProtectedRoute path="/activity/new" component={AddActivityPage} />
              <ProtectedRoute exact path="/activities/:id" component={ActivityPage} />
              <Route component={PageNotFound} />
            </Switch>
          </AuthContainer>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
