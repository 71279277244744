/* eslint-disable react/prop-types */
import React from 'react';
import { Heading, Card, Flex } from 'rebass';

function Loading() {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{ position: 'absolute', top: 80, left: 0, right: 0, bottom: 0 }}
    >
      <Card p={4}>
        <Heading variant="marketing">Cassidy</Heading>
      </Card>
    </Flex>
  );
}

export default Loading;
