import React, { FunctionComponent } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Label, Select } from '@rebass/forms';
import get from 'lodash/get';
import { DistanceUnit, IntervalCreateInput, IntervalType } from 'generated/gql';
import DurationInput from './DurationInput';
import { useFormContext } from 'react-hook-form';
import NumberInput from './NumberInput';
import FormInput from './FormInput';

interface IntervalInputProps {
  name: string;
  defaultValue: IntervalCreateInput;
  required?: boolean;
  showType?: boolean;
}

const IntervalInput: FunctionComponent<IntervalInputProps> = ({
  name,
  defaultValue,
  showType = false,
  required = false,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const intervalErrors = get(errors, name);

  return (
    <>
      <Flex>
        <Box mr={2}>
          <NumberInput
            label="Distance"
            name={`${name}.displayDistance`}
            defaultValue={defaultValue.displayDistance || 0}
            required={required}
          />
        </Box>

        <Box mr={3}>
          <Text as={Label} variant="label" mb={2}>
            Unit
          </Text>
          <Select
            {...register(`${name}.displayUnit`, { required })}
            sx={{ width: 72 }}
            defaultValue={defaultValue.displayUnit || DistanceUnit.Miles}
          >
            <option value={DistanceUnit.Miles}>mi</option>
            <option value={DistanceUnit.Kilometers}>km</option>
            <option value={DistanceUnit.Meters}>m</option>
          </Select>
        </Box>
        <DurationInput
          label="Time"
          name={`${name}.duration`}
          defaultValue={defaultValue.duration}
          required={required}
        />

        {showType ? (
          <Box ml={2}>
            <Text as={Label} variant="label" mb={2}>
              Type
            </Text>
            <Select
              {...register(`${name}.type`, { required })}
              sx={{ width: 160 }}
              defaultValue={defaultValue.type || IntervalType.Default}
            >
              <option value={IntervalType.Workout}>Rep</option>
              <option value={IntervalType.Default}>Recovery</option>
              <option value={IntervalType.Rest}>Rest</option>
            </Select>
          </Box>
        ) : (
          <FormInput
            type="hidden"
            name={`${name}.type`}
            defaultValue={defaultValue?.type || IntervalType.Default}
            required={required}
            showError={false}
          />
        )}
      </Flex>
      {intervalErrors &&
        Object.keys(intervalErrors).map((key) => (
          <Text key={`${name}-${key}`} mt={2} variant="error">
            {get(intervalErrors, `${key}.message`, `Error in ${name}`)}
          </Text>
        ))}
    </>
  );
};

export default IntervalInput;
