import { DistanceUnit } from 'generated/gql';

type NumberFormat = DistanceUnit | 'percent' | 'decimal';

/**
 * Append units to the end of a formatted value.
 * @param formattedNumber A number that has already been formatted by a formatter.
 * @param format The format used to format the value.
 * @returns The formatted number with a unit suffix attached. If no units are required,
 * return formattedNumber as is.
 */
const appendUnits = (formattedNumber: string, format: NumberFormat): string => {
  switch (format) {
    case DistanceUnit.Kilometers:
      return `${formattedNumber} km`;
    case DistanceUnit.Meters:
      return `${formattedNumber} m`;
    case DistanceUnit.Miles:
      return `${formattedNumber} mi`;
    default:
      return formattedNumber;
  }
};

export const formatNumber = (value: number, format: NumberFormat = 'decimal') => {
  if (Number.isNaN(value)) {
    return '';
  }
  // For all options, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
  const formatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    style: format,
  };

  switch (format) {
    case DistanceUnit.Kilometers:
    case DistanceUnit.Meters:
    case DistanceUnit.Miles:
      // Not all browsers support the unit type yet, so we'll stick with decimal
      // and append the unit manually
      formatOptions.style = 'decimal';
      break;
    case 'percent':
      formatOptions.maximumFractionDigits = 0;
      break;
    default:
      break;
  }

  const formatter = new Intl.NumberFormat('en-us', formatOptions);
  const formattedValue = formatter.format(value);
  const formattedWithUnits = appendUnits(formattedValue, format);

  return formattedWithUnits;
};
