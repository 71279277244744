import React from 'react';
import { motion } from 'framer-motion';
import { useFeedQueryQuery } from 'generated/gql';
import { Link } from 'react-router-dom';
import { Divider } from 'theme-ui';
import Activity from 'components/Activities/Activity';
import Card from 'components/Card';
import LogLayout from 'components/LogLayout';
import Loading from 'components/Loading';

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

function FeedPage() {
  const { loading, data, error } = useFeedQueryQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: 0,
      take: 12,
    }
  });

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <LogLayout>
      <Card padded={false} title="My runs">
        {data.feed.length > 0 ? (
          data.feed.map((activity, index) => (
            <motion.div key={activity.id} variants={item}>
              {index > 0 && <Divider />}
              <Link to={`/activities/${activity.id}`}>
                <Activity activity={activity} variant="small" />
              </Link>
            </motion.div>
          ))
        ) : (
          <Card>Add runs to get started</Card>
        )}
      </Card>
    </LogLayout>
  );
}

export default FeedPage;
