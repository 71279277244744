import React, { Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Text, Box, Flex } from 'rebass';
import {
  useGetActivityQuery,
  useDeleteActivityMutation,
  IntervalType,
  useUpdateActivityMutation,
  ActivityUpdateInput,
  GetShoesDocument,
} from 'generated/gql';
import { useToasts } from 'react-toast-notifications';
import isEqual from 'lodash/isEqual';
import { toMinutes, getPace } from '../helper/convert';
import { formatNumber } from '../helper/format';
import ActivityEditForm from '../components/Forms/ActivityEditForm';
import Modal from 'components/Modal';
import LogLayout from 'components/LogLayout';
import Card from 'components/Card';
import Activity from 'components/Activities/Activity';
import StravaLines from 'components/StravaLines';

function ActivityPage() {
  const { id } = useParams<{ id: string }>();
  const { error, data } = useGetActivityQuery({
    variables: { id },
  });

  const { addToast } = useToasts();
  const history = useHistory();
  const [deleteActivity] = useDeleteActivityMutation({ variables: { id } });
  const [updateActivity] = useUpdateActivityMutation();

  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const handleDelete = () => {
    deleteActivity()
      .then(({ data }) => {
        addToast(`Deleted activity ${data?.deleteActivity.title}`, { appearance: 'success' });
        history.push('/activities');
      })
      .catch((err: Error) => {
        addToast(err.message, { appearance: 'error' });
      });
  };
  if (!data || error) {
    return null;
  } else {
    const { intervals, activityDate } = data.activity;

    const handleUpdate = (rawFormData: ActivityUpdateInput) => {
      // We should only send updates for fields that have changed.
      // Ensure the activity id is send along with the request
      let didUpdate = false;
      const updateFields: ActivityUpdateInput = { id: data.activity.id };

      // Special handling for shoes
      if (rawFormData.shoe?.id !== data.activity.shoe?.id) {
        updateFields.shoe = { id: rawFormData.shoe?.id };
        didUpdate = true;
      }

      // Handle the rest of the fields
      const updateKeys: Array<keyof ActivityUpdateInput> = ['title', 'description', 'intervals'];
      updateKeys.forEach((key) => {
        if (!isEqual(rawFormData[key], data.activity[key])) {
          updateFields[key] = rawFormData[key];
          didUpdate = true;
        }
      });

      if (didUpdate) {
        updateActivity({
          variables: { input: updateFields },
          // User can update the distance on a shoe when linking it
          // to an activity. Fetch all shoes again so that we get
          // the most up to date data
          refetchQueries: [{ query: GetShoesDocument }],
        })
          .then(({ data }) => {
            addToast(`Updated activity ${data?.updateActivity.title}`, { appearance: 'success' });
            close();
          })
          .catch((err: Error) => {
            addToast(err.message, { appearance: 'error' });
          });
      } else {
        // Do something if none of the fields changed?
        close();
      }
    };

    console.log(data.activity);

    return (
      <LogLayout centerTitle={activityDate} prioritizeCenterContent>
        <Modal isOpen={showDialog} onDismiss={close}>
          <ActivityEditForm activity={data.activity} onSubmit={handleUpdate} />
        </Modal>
        <Box>
          <Card padded={false}>
            <Activity activity={data.activity} onDelete={handleDelete} onEdit={open} />
            {data.activity.stravaActivity && data.activity.stravaActivity.polyline && (
              <>
                <Box height={1} width="100%" bg="darkerBackground" />
                <Card padded={true}>
                  <StravaLines width={800} height={320} polyLine={data.activity.stravaActivity.polyline} />
                </Card>
              </>
            )}
          </Card>
          <Box mb={3} />
          {!!intervals && intervals.length > 1 && (
            <Card title="Intervals" padded={false}>
              <Box width={'100%'}>
                <Flex px={3} py={3} width={'100%'}>
                  <Text flex={1} mr={2} color={'mutedText'}>
                    Type
                  </Text>
                  <Text flex={1} mr={2} color={'mutedText'}>
                    Distance
                  </Text>
                  <Text flex={1} mr={2} color={'mutedText'}>
                    Duration
                  </Text>
                  <Text sx={{ textAlign: 'right' }} flex={1} mr={2} color={'mutedText'}>
                    Pace
                  </Text>
                </Flex>
                <Box height={1} width="100%" bg="darkerBackground" />
                {intervals.map(({ type, displayDistance, displayUnit, duration }, index) => {
                  return (
                    <Fragment key={index}>
                      <Flex
                        color={type === IntervalType.Workout ? 'text' : 'mutedText'}
                        px={3}
                        py={2}
                        flex={1}
                        width={'100%'}
                        key={`interval-${index}`}
                      >
                        <Text flex={1} mr={2}>
                          {type.toLowerCase()}
                        </Text>
                        <Text flex={1} mr={2}>
                          {formatNumber(displayDistance, displayUnit)}
                        </Text>
                        <Text flex={1} mr={2}>
                          {toMinutes(duration)}
                        </Text>
                        <Text sx={{ textAlign: 'right' }} flex={1} mr={2}>
                          {getPace({
                            units: displayUnit,
                            distance: displayDistance,
                            duration,
                          })}
                        </Text>
                      </Flex>
                      <Box height={1} width="100%" bg="darkerBackground" />
                    </Fragment>
                  );
                })}
              </Box>
            </Card>
          )}
        </Box>
      </LogLayout>
    );
  }
}

export default ActivityPage;
