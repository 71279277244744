/* eslint-disable react/prop-types */
import React from 'react';
import Select, { Theme } from 'react-select';
import myTheme from '../theme';
import { useColorMode } from 'theme-ui';

const options = [
  { value: 'ADIDAS', label: 'adidas' },
  { value: 'ALTRA', label: 'Altra' },
  { value: 'ARCTERYX', label: 'Arcteryx' },
  { value: 'ASICS', label: 'asics' },
  { value: 'BROOKS', label: 'Brooks' },
  { value: 'HOKAONEONE', label: 'Hoke One One' },
  { value: 'INOV8', label: 'Inov8' },
  { value: 'LASPORTIVA', label: 'La Sportia' },
  { value: 'MERRELL', label: 'Merell' },
  { value: 'MIZUNO', label: 'Mizuno' },
  { value: 'NEWBALANCE', label: 'New Balance' },
  { value: 'NEWTON', label: 'Newton' },
  { value: 'NIKE', label: 'Nike' },
  { value: 'ON', label: 'ON' },
  { value: 'OTHER', label: '' },
  { value: 'PUMA', label: 'Puma' },
  { value: 'REEBOK', label: 'Reebok' },
  { value: 'SALOMON', label: 'Salomon' },
  { value: 'SAUCONY', label: 'Saucony' },
  { value: 'SKECHERSPERFORMANCE', label: 'Skechers' },
  { value: 'THENORTHFACE', label: 'The North Face' },
  { value: 'TOPOATHLETIC', label: 'Topo' },
  { value: 'UNDERARMOUR', label: 'Under Armour' },
];

const lightTheme = (theme: Theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      neutral0: myTheme.colors.background,
      primary25: myTheme.colors.darkerBackground,
      primary50: myTheme.colors.darkerBackground,
      primary75: myTheme.colors.darkerBackground,
      primary: myTheme.colors.secondary,
    },
  };
};

const darkTheme = (theme: Theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      neutral0: myTheme.colors.modes.dark.darkerBackground,
      primary25: myTheme.colors.modes.dark.highlight,
      primary50: myTheme.colors.modes.dark.highlight,
      primary75: myTheme.colors.modes.dark.highlight,
      primary: myTheme.colors.modes.dark.secondary,
    },
  };
};

function BrandDropdown({
  selectedOption,
  handleChange,
}: {
  selectedOption: {
    value: string;
    label: string;
  } | null;
  handleChange: any;
}) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  return (
    <Select
      theme={(theme) => (isDark ? darkTheme(theme) : lightTheme(theme))}
      placeholder="Select a brand"
      value={selectedOption?.value ? selectedOption : null}
      onChange={handleChange}
      options={options}
    />
  );
}

export default BrandDropdown;
