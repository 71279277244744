import React, { Fragment, FunctionComponent } from 'react';
import { Edit, Trash } from 'react-feather';
import { Button, Flex, Heading, Link, Text, Box } from 'rebass';
import NanoClamp from 'nanoclamp';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { ReactComponent as StravaLogo } from 'assets/images/Strava.svg';
import timeAgo from '../../helper/timeAgo';
import { Activity as ActivityType } from 'types/activity';
import ActivityStats from './ActivityStats';
import { Divider, useColorMode } from 'theme-ui';

interface ActivityProps {
  activity: ActivityType;
  onDelete?: () => void;
  onEdit?: () => void;
  variant?: 'small' | 'regular';
}

const Activity: FunctionComponent<ActivityProps> = ({ activity, onDelete, onEdit, variant = 'regular' }) => {

  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const {
    title,
    description,
    activityDate,
    displayDistance,
    displayUnit,
    shoe,
    stravaActivity,
    totalDuration,
  } = activity;

  return (
    <Fragment>
      <Box p={3}>
        {variant === 'small' ? (
          <Heading color="muted" mb={2} variant="caps">
            {timeAgo(activityDate)}
          </Heading>
        ) : (
          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              {stravaActivity && (
                <Tooltip
                  key={activity.id}
                  label={`View activity on strava`}
                  style={{
                    background: 'hsla(0, 0%, 0%, 0.9)',
                    color: 'white',
                    fontFamily: 'HK Compakt',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '0.5em 1em',
                  }}
                >
                  <Link target="_blank" href={stravaActivity.url}>
                    <StravaLogo fill={isDark ? '#747da9' : '#fc4c01'} width={16} height={16} />
                  </Link>
                </Tooltip>
              )}
              <Heading ml={stravaActivity && 2} variant={'caps'}>
                {timeAgo(activityDate)}
              </Heading>

            </Flex>
            <Box>
              <Button variant="tertiary" sx={{ color: 'primary' }} bg="transparent" onClick={onEdit}>
                <Edit size={16} />
              </Button>
              <Button variant="tertiary" sx={{ color: 'primary' }} bg="transparent" onClick={onDelete}>
                <Trash size={16} />
              </Button>
            </Box>
          </Flex>
        )}

        <Box>
          <Heading variant="display" fontSize={variant === 'small' ? [2, 3, 4] : undefined}>
            {title}
          </Heading>
          {variant === 'small' && description && (
            <Text mt={2} color={'mutedText'} fontSize={[2, 2, 3]}>
              <NanoClamp is="p" lines={3} text={description} />
            </Text>
          )}
        </Box>
      </Box>

      {displayDistance > 0 && (
        <ActivityStats distance={displayDistance} duration={totalDuration} distanceUnit={displayUnit} shoe={shoe} />
      )}

      {variant !== 'small' && description && (
        <Fragment>
          <Divider />
          <Text px={3} pb={2}>
            {description}
          </Text>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Activity;
