/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Heading } from 'rebass';

const LayoutBox = styled(Box)`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr 0.8fr;
  grid-column-gap: 24px;
  grid-area: main;
  grid-template-areas: 'leftContent centerContent rightContent';
`;

const HomePage: React.FC = () => {
  return (
    <LayoutBox>
      <Flex style={{ gridArea: 'centerContent' }} flexDirection="column">
        <Heading variant="caps">Nothing here</Heading>
      </Flex>
      <Box sx={{ gridArea: 'leftContent' }} />
      <Box style={{ gridArea: 'rightContent' }} />
    </LayoutBox>
  );
};

export default HomePage;
