import React, { FunctionComponent } from 'react';
import { DistanceUnit, useGetActivityTrendsQuery } from 'generated/gql';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import { Box, Flex, Heading, Text } from 'rebass';
import styled from '@emotion/styled';
import Card from '../components/Card';
import { formatNumber } from 'helper/format';
import { toMiles } from 'helper/convert';

const TrendsGrid = styled(Box)`
  display: grid;
  grid-template-columns: 4fr 4fr 3fr;
  grid-row-gap: 8px;
`;

const ActivityTrends: FunctionComponent = () => {
  // Base activity trends off of midnight tomorrow local time. This will
  // count any activities today in the rollup
  const referenceDate = startOfTomorrow();
  const { loading, error, data } = useGetActivityTrendsQuery({
    variables: { endDate: referenceDate.toISOString() },
  });

  if (error) return <p>{error.message}</p>;
  if (loading || !data) return <p>Loading ...</p>;

  const { current: last7Distance, percentChange: last7Delta } = data.activityTrends.weeklyDistance;
  const { current: last30Distance, percentChange: last30Delta } = data.activityTrends.monthlyDistance;

  return (
    <Card>
      <TrendsGrid>
        {/* Grid first row */}
        <Heading variant="caps">Trends</Heading>
        <Heading variant="caps" sx={{ textAlign: 'right' }}>
          Dist
        </Heading>
        <Heading variant="caps" sx={{ textAlign: 'right' }}>
          Change
        </Heading>
        {/* Grid second row */}
        <Flex flexDirection="column">
          <Text>Last 7 days</Text>
        </Flex>
        <Text sx={{ textAlign: 'right' }}>{formatNumber(toMiles(last7Distance), DistanceUnit.Miles)}</Text>
        <Text sx={{ textAlign: 'right' }}>
          {typeof last7Delta === 'number' ? formatNumber(last7Delta, 'percent') : 'None'}
        </Text>
        {/* Grid third row */}
        <Text>Last 30 days</Text>
        <Text sx={{ textAlign: 'right' }}>{formatNumber(toMiles(last30Distance), DistanceUnit.Miles)}</Text>

        <Text sx={{ textAlign: 'right' }}>
          {typeof last30Delta === 'number' ? formatNumber(last30Delta, 'percent') : 'None'}
        </Text>
      </TrendsGrid>
    </Card>
  );
};

export default ActivityTrends;
