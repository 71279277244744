import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useIsAuthenticatedQuery } from 'generated/gql';
import NavBar from './NavBar';
import { Box } from 'rebass';
import styled from '@emotion/styled';

const LayoutBox = styled(Box)`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr 0.8fr;
  grid-template-rows: 100px 1fr 72px;
  grid-template-areas: 'nav nav nav' 'main main main' 'footer footer footer';
`;

// <NavBar />

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  path: string;
}

const ProtectedRoute = ({ component: Component, path, ...rest }: ProtectedRouteProps) => {
  const { data, error, loading } = useIsAuthenticatedQuery();

  if (loading) {
    // No defined loading state yet. Even though IsAuthenticated is a client-only query,
    // the hook returns loading=true as an initial state on the component's first render.
    return null;
  }

  return !error && data?.isAuthenticated ? (
    <Route
      path={path}
      {...rest}
      render={(matchProps) => (
        <LayoutBox>
          <NavBar />
          <Component {...matchProps} />
        </LayoutBox>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
};

export default ProtectedRoute;
