/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactNode, ReactNodeArray } from 'react';
import { Link as StyledLink, LinkProps as StyledLinkProps, Text } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import { ExternalLink } from 'react-feather';

interface GenericLinkProps extends StyledLinkProps {
  children?: ReactNode | ReactNodeArray;
}

interface ExternalLinkProps extends GenericLinkProps {
  href: string;
}

interface InternalLinkProps extends GenericLinkProps {
  to: string;
  exact?: boolean;
}

type LinkProps = InternalLinkProps | ExternalLinkProps;

const styles = {
  display: 'flex',
  alignItems: 'center',
  px: 2,
  py: 2,
  color: 'inherit',
  textDecoration: 'none',
  fontSize: 1,
  fontFamily: 'inherit',
  '&.active': {
    color: 'primary',
  },
};

const Link = (props: LinkProps) => {
  if (props.href) {
    const { href, children } = props as ExternalLinkProps;
    return (
      // @ts-ignore
      <StyledLink {...props} href={href} sx={styles} {...props}>
        <Text mr={1}>{children}</Text>
        <ExternalLink size={12} color="white" />
      </StyledLink>
    );
  }

  const { children } = props as InternalLinkProps;

  return (
    // @ts-ignore
    <StyledLink as={RouterLink} {...props} sx={styles} {...props}>
      {children}
    </StyledLink>
  );
};

export default Link;
