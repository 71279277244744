import { InMemoryCache } from '@apollo/client';
import { AUTH_TOKEN } from 'constant';
import { userInfo } from './local-state';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isAuthenticated: {
          read: () => {
            // Assume that a user is logged in if they have a stored Auth Token. The validity of that
            // token will be asserted before it is stored, and on every API request so for now assume
            // that there is no need to validate it on every call of this function
            return !!localStorage.getItem(AUTH_TOKEN);
          },
        },
        gear: {
          read: (cachedValue: any[] | undefined, { readField }) => {
            // sort by most recently updated
            return cachedValue?.slice().sort((a, b) => {
              // Apollo client cache stores references to other objects in
              // the cache. For example, "gear" points to individual shoes
              const updatedAtA = readField<Date>('updatedAt', a);
              const updatedAtB = readField<Date>('updatedAt', b);

              if (updatedAtA && updatedAtB) {
                return updatedAtA <= updatedAtB ? 1 : -1;
              }

              // If updatedAt is not defined for some object (this shouldn't
              // happen), put it at the end of the array
              if (updatedAtA) {
                return 1;
              }

              return -1;
            });
          },
        },
      },
    },
    User: {
      fields: {
        email: {
          read: () => {
            const { email } = userInfo();
            return email;
          },
        },
      },
    },
  },
});

export default cache;
