import React, { useEffect } from 'react';
import { Button, Box, Flex, Text, Link } from 'rebass';
import { useToasts } from 'react-toast-notifications';
import { makeStravaAuthenticationUrl } from '../services/strava';
import { useIsStravaConnectedQuery } from 'generated/gql';
import { ReactComponent as StravaLogo } from 'assets/images/Strava.svg';

import Card from './Card';

const StravaWidget = () => {
  const { addToast } = useToasts();
  const { loading, error, data } = useIsStravaConnectedQuery();

  useEffect(() => {
    if (error) {
      addToast(error.message, { appearance: 'error' });
    }
  }, [error, addToast]);

  if (error) {
    return (
      <Flex alignItems="center" flexDirection="column">
        <Text>{error.message}</Text>
      </Flex>
    );
  } else if (loading) {
    return (
      <Flex alignItems="center" flexDirection="column">
        <Text>Loading...</Text>
      </Flex>
    );
  } else if (data?.me.strava) {
    return null;
  } else {
    return (
      <Flex alignItems="center" flexDirection="column">
        <Card sx={{ width: '100%', mb: 3 }}>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box mr={3} sx={{ fill: '#fc4c05' }}>
                <StravaLogo />
              </Box>
              <Text sx={{ color: 'muted' }}>Want to sync your runs?</Text>
            </Flex>
            <Link href={makeStravaAuthenticationUrl()}>
              <Button>Connect to Strava</Button>
            </Link>
          </Flex>
        </Card>
      </Flex>
    );
  }
};

export default StravaWidget;
