import React, { useEffect } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { Button, Box } from 'rebass';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Input } from '@rebass/forms';
import { ActivityCreateInput, ActivityType, IntervalCreateInput, IntervalType, WorkoutType } from 'generated/gql';
import { useAddActivityMutation } from 'generated/gql';
import FormInput from 'components/Forms/Inputs/FormInput';
import ShoeSelectInput from 'components/Forms/Inputs/ShoeSelectInput';
import IntervalWorkoutInputs from 'components/Forms/Groups/IntervalWorkoutInputs';
import { defaultDateTimeLocal } from 'helper/date';
import SelectInput from './Inputs/SelectInput';

const defaultValues: ActivityCreateInput = {
  title: '',
  type: ActivityType.Run,
  workoutType: WorkoutType.Default,
  description: '',
  intervals: [],
  shoe: undefined,
  activityDate: defaultDateTimeLocal(),
};

function ActivityCreateForm() {
  const { addToast } = useToasts();
  const history = useHistory();
  const formMethods = useForm({
    defaultValues,
  });
  const { handleSubmit, setValue } = formMethods;
  const workoutType = useWatch({ control: formMethods.control, name: 'workoutType' });

  useEffect(() => {
    let intervals: IntervalCreateInput[] = [];
    switch (workoutType) {
      case 'TEMPO':
        // Tempo workout type has 3 intervals minimum - warmup, rep, cooldown
        intervals = [
          { type: IntervalType.Warmup, duration: 0 },
          { type: IntervalType.Workout, duration: 0 },
          { type: IntervalType.Cooldown, duration: 0 },
        ];
        break;
      case 'WORKOUT':
        // Workout workout type has multiple interval sets
        intervals = [
          { type: IntervalType.Warmup, duration: 0 },
          { type: IntervalType.Workout, duration: 0 },
          { type: IntervalType.Default, duration: 0 },
          { type: IntervalType.Cooldown, duration: 0 },
        ];
        break;
      case 'OTHER':
        intervals = [];
        break;
      default:
        // Default workout type has a single interval. Remove any intervals and add the default
        intervals = [{ type: IntervalType.Default, duration: 0 }];
        break;
    }

    setValue('intervals', intervals);
  }, [workoutType, setValue]);

  const [addActivity] = useAddActivityMutation();

  const onSubmit = (data: ActivityCreateInput) => {
    if (!data.shoe?.id) {
      data.shoe = undefined;
    }

    // Allow for no intervals
    if (data.intervals?.length === 0) {
      delete data.intervals;
    }

    addActivity({
      variables: {
        input: data,
      },
      // TODO: update cache for feed
    })
      .then(({ data }) => {
        addToast('Saved workout', { appearance: 'success' });
        history.push(`/activities/${data?.createActivity.id}`);
      })
      .catch((err: Error) => {
        console.log(err);
        addToast(err.message, { appearance: 'error' });
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Title */}
        <Box mb={[2, 4, 4]}>
          <FormInput type="text" name="title" label="Title" placeholder="Easy run" required />
        </Box>

        {/* Date */}
        <Box mb={[2, 4, 4]}>
          <FormInput type="datetime-local" name="activityDate" label="Date" required />
        </Box>

        {/* Shoe */}
        <ShoeSelectInput />

        {/* Intervals */}
        <Box mb={[2, 4, 4]}>
          <SelectInput
            name="workoutType"
            label="Workout Type"
            options={Object.keys(WorkoutType).map((key) => ({
              label: key,
              id: WorkoutType[key as keyof typeof WorkoutType],
            }))}
          />
        </Box>
        <IntervalWorkoutInputs name="intervals" workoutType={workoutType || WorkoutType.Default} />

        {/* Description */}
        <Box mb={[2, 4, 4]}>
          <FormInput name="description" type="textarea" label="Description" placeholder="How did you feel?" />
        </Box>

        <Button as={Input} variant="primary" type="submit" />
      </form>
    </FormProvider>
  );
}

export default ActivityCreateForm;
