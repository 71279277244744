import { Shoe, ShoeUpdateInput } from 'generated/gql';
import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Heading } from 'rebass';
import { Input } from '@rebass/forms';
import FormInput from './Inputs/FormInput';
import CheckboxInput from './Inputs/CheckboxInput';

interface ShoeEditFormProps {
  shoe: Shoe;
  onSubmit: (values: Shoe) => void;
}

const ShoeEditForm: FunctionComponent<ShoeEditFormProps> = ({ shoe, onSubmit }) => {
  const formMethods = useForm({ defaultValues: mapToFormValues(shoe) });
  const { handleSubmit } = formMethods;

  return (
    <Box>
      <Heading mb={3}>Edit Shoe</Heading>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={[2, 4, 4]}>
            <FormInput type="text" name="name" label="Nickname" placeholder="Super shoes" required={false} />
          </Box>
          <Box mb={[2, 4, 4]}>
            <CheckboxInput name="isRetired" label="Retired" />
          </Box>
          <Button as={Input} variant="primary" type="submit" />
        </form>
      </FormProvider>
    </Box>
  );
};

function mapToFormValues(shoe: Shoe): ShoeUpdateInput {
  return {
    id: shoe.id,
    name: shoe.name,
    isRetired: shoe.isRetired,
  };
}

export default ShoeEditForm;
