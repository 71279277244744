import React, { ChangeEvent, FunctionComponent } from 'react';
import { Box, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { fromDurationInput, toMinutes } from 'helper/convert';

const hours = /[0-9]/;
const tens = /[0-5]/;
const ones = /[0-9]/;
const mask = [hours, ':', tens, ones, ':', tens, ones];

interface DurationInputProps {
  label: string;
  name: string;
  required?: boolean;
  defaultValue?: number;
}

const DurationInput: FunctionComponent<DurationInputProps> = ({ label, name, defaultValue, required = false }) => (
  <Box>
    <Text variant="label" mb={2} as={Label}>
      {label}
    </Text>
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { onChange, value, name: fieldName }, fieldState: { error } }) => {
        return (
          <>
            <Input
              mask={mask}
              alwaysShowMask
              as={InputMask}
              placeholder="h:mm:ss"
              name={fieldName}
              value={formatValue(value)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChange(parseValue(event.target.value));
              }}
            />
            {error && (
              <Text mt={2} variant="error">
                {error.message || 'Invalid duration.'}
              </Text>
            )}
          </>
        );
      }}
    />
  </Box>
);

/**
 * Convert the value stored in state to a string that can be
 * passed to the DOM's input element.
 * @param value The value stored in the form's state.
 * @returns The value passed to the DOM's input element.
 */
function formatValue(value: any): string {
  // console.log(value);
  if (typeof value === 'number') {
    if (Number.isNaN(value)) {
      return '';
    }
    return toMinutes(value);
  }
  return String(value);
}

/**
 * Convert the string from the DOM's input element to a value
 * that is stored in the form's state. If this value is a properly
 * formatted duration (h:mm:ss), we convert to seconds to send to
 * the API. Else, return as is so the user can keep typing.
 * @param value The value from the DOM's input element.
 * @returns The value to be stored in form state.
 */
function parseValue(value: string): string | number {
  // TODO: use this for validation too
  if (value.match(/\d:\d\d:\d\d/)) {
    return fromDurationInput(value);
  }
  return value;
}

export default DurationInput;
