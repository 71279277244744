import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { checkLoginResponse } from '../services/auth';
import Loading from '../components/Loading';
import { userInfo } from 'services/apollo/local-state';
import { User } from 'types/user';

const PostAuth = () => {
  const history = useHistory();
  useEffect(() => {
    checkLoginResponse()
      .then((user) => {
        userInfo(user);
        history.replace('/activities');
      })
      .catch(() => {
        // TODO: Maybe have an error popup or something?
        userInfo({} as User);
        history.replace('/');
      });
  }, [history]);
  return <Loading />;
};

export default PostAuth;
