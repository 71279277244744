import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box, Button, Text } from 'rebass';
import { signUp, login } from 'services/auth';
import { Redirect } from 'react-router-dom';
import { useColorMode } from 'theme-ui';
import MediaQuery from 'react-responsive';
import { ReactComponent as LightGrayLogo } from 'assets/images/logo.svg';
import MarketingIcon from '../components/MarketingIcon';
import { ReactComponent as LightRedLogo } from 'assets/images/logo.svg';
import { ReactComponent as LightXL } from 'assets/images/light-xl.svg';
import { ReactComponent as DarkXL } from 'assets/images/dark-xl.svg';
import { ReactComponent as LightSM } from 'assets/images/light-sm.svg';
import { ReactComponent as DarkSM } from 'assets/images/dark-sm.svg';
import { useIsAuthenticatedQuery } from 'generated/gql';

const Grid = styled(Box)`
  height: 100%;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0;
    grid-column-gap: 0;
    grid-template-areas: 'left right';
  }
`;

const Body = styled(Text)`
  font-family: 'GT America Extended';
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const HomePage: React.FC = () => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const { data, error, loading } = useIsAuthenticatedQuery();
  const isAuthenticated = !error && !loading && data?.isAuthenticated;
  if (isAuthenticated) {
    // If the user is logged in, go straight to their log
    return <Redirect to="/activities" />;
  }
  return (
    <>
      <Grid sx={{ gridArea: 'main' }}>
        <Flex
          alignItems="center"
          px={[4, 5, 5, 5]}
          py={[4, 5, 5, 5]}
          sx={{
            gridArea: 'left',
            flexDirection: 'column',
            position: 'relative',
            width: ['100%', '100%', '100%', 'unset'],
            backgroundColor: isDark ? 'darkerBackground' : 'backgroundAccent',
            height: ['unset', 'unset', '100%', '100%'],
          }}
        >
          <Box sx={{ svg: { fill: isDark ? 'hsl(230, 25%, 25%)' : '#9f8e6a' } }}>
            <LightGrayLogo />
          </Box>
          <MediaQuery minDeviceWidth={'900px'}>
            {isDark ? <DarkXL style={{ width: '100%', flex: 1 }} /> : <LightXL style={{ width: '100%', flex: 1 }} />}
          </MediaQuery>
          <MediaQuery maxDeviceWidth={'900px'}>{isDark ? <DarkSM /> : <LightSM />}</MediaQuery>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          py={[0, 0, 0, 5]}
          sx={{ gridArea: 'right', position: 'relative' }}
        >
          <Box sx={{ svg: { fill: isDark ? '#76ddc6' : '#EF5858' } }} display={['none', 'none', 'none', 'unset']}>
            <LightRedLogo />
          </Box>
          <Flex
            flex="1"
            maxWidth={880}
            flexDirection="column"
            justifyContent="center"
            p={4}
            sx={{ gridArea: 'right', position: 'relative' }}
          >
            <Body mt={4} fontSize={[4, 4, 4, 5]} mb={4}>
              Cassidy is a private running log, built for runners. No feeds. Think of it as your favorite spreadsheet,
              just better.
            </Body>
            <Body fontSize={[4, 4, 4, 5]} mb={4}>
              With Cassidy you can track:
            </Body>
            <Flex flexDirection="column">
              <Flex alignItems="center" mb={2}>
                <MarketingIcon name="Trends" isDark={isDark} />
                <Body ml={3} fontSize={[4, 4, 4, 5]}>
                  How you feel over time.
                </Body>
              </Flex>
              <Flex alignItems="center" mb={2}>
                <MarketingIcon name="Journal" isDark={isDark} />
                <Body ml={3} fontSize={[4, 4, 4, 5]}>
                  Your running diary.
                </Body>
              </Flex>
              <Flex alignItems="center" mb={2}>
                <MarketingIcon name="Metrics" isDark={isDark} />
                <Body ml={3} fontSize={[4, 4, 4, 5]}>
                  Your hard-earned miles.
                </Body>
              </Flex>
            </Flex>

            <Flex alignItems="center" mt={4} flexDirection={['column', 'unset']}>
              <Button
                name="Sign up"
                mr={[0, 0, 3, 3]}
                sx={{ width: ['100%', 'unset'] }}
                variant="marketing"
                onClick={signUp}
              >
                Sign up now
              </Button>

              <Flex alignItems="center" sx={{ width: ['100%', 'unset'] }}>
                <Text fontSize={['18px', 4, 4, 4]} mr={1}>
                  or
                </Text>
                <Button name="Login" fontWeight="medium" variant="marketingLink" onClick={login}>
                  Log in
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    </>
  );
};

export default HomePage;
