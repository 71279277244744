import React from 'react';
import { Button } from 'rebass';
import { useColorMode } from 'theme-ui';
import { Moon, Sun } from 'react-feather';

const ToggleMode = () => {
  const [colorMode, setColorMode] = useColorMode();
  return (
    <Button
      variant="primary"
      onClick={() => {
        setColorMode(colorMode === 'default' ? 'dark' : 'default');
      }}
    >
      {colorMode === 'default' ? <Moon size={16} /> : <Sun size={16} />}
    </Button>
  );
};

export default ToggleMode;
