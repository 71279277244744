import convert from 'convert-units';
import { DistanceUnit } from 'generated/gql';
import { Duration } from 'luxon';

export const toMiles = (d: number) => {
  return convert(d).from('m').to('mi');
};

export const toMeters = (m: number) => {
  return convert(m).from('mi').to('m');
};

export const toSeconds = (t: number) => parseInt(convert(t).from('min').to('s').toFixed(2));

export const fromDurationInput = (t: string) => {
  const durationParts = t.split(':');
  const hours = convert(parseInt(durationParts[0])).from('h').to('s');
  const minutes = convert(parseInt(durationParts[1])).from('min').to('s');
  const seconds = parseInt(durationParts[2]);

  return hours + minutes + seconds;
};

export const toMinutes = (t: number) => Duration.fromMillis(1000 * t).toFormat('h:mm:ss');

export const getPace = ({ units, distance, duration }: { units: DistanceUnit; distance: number; duration: number }) => {
  if (!distance || !duration) {
    return '0:00 /mi';
  }

  const convertedDuration = duration * 1000; // convert s to ms
  let paceCalculation: number;
  switch (units) {
    case DistanceUnit.Miles:
      paceCalculation = convertedDuration / distance;
      break;
    case DistanceUnit.Meters:
      paceCalculation = convertedDuration / toMiles(distance);
      break;
    case DistanceUnit.Kilometers:
      paceCalculation = convertedDuration / toMiles(distance * 1000);
      break;
    default:
      throw new Error(`Unrecognized unit ${units}!`);
  }

  // For now, always show pace in per mile
  // TODO: do this calculation on the API
  return `${Duration.fromMillis(paceCalculation).toFormat('m:ss')} /mi`;
};
