import { makeVar } from '@apollo/client';
import { User } from 'types/user';

// Using Apollo Reactive Variables (https://www.apollographql.com/docs/react/local-state/reactive-variables/)
// to store local state

/**
 * Metadata about the user such as id, email, etc.
 */
export const userInfo = makeVar({} as User);
