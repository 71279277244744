/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import ShoeForm from '../components/ShoeCreationForm';

const LayoutBox = styled(Box)`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr 0.8fr;
  grid-column-gap: 24px;
  grid-area: main;
  grid-template-areas: 'leftContent centerContent rightContent';

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-areas: 'centerContent' 'leftContent' 'rightContent';
  }
`;

class ShoeAddPage extends Component<{ location: any }> {
  render() {
    return (
      <LayoutBox px={[2, 0]}>
        <Box sx={{ gridArea: 'leftContent' }} />
        <Flex style={{ gridArea: 'centerContent' }} flexDirection="column">
          <ShoeForm />
        </Flex>
        <Box style={{ gridArea: 'rightContent' }} />
      </LayoutBox>
    );
  }
}

export default ShoeAddPage;
