import React, { useState } from 'react';
import styled from '@emotion/styled';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { DistanceUnit, useGetWeeklyActivityQuery } from 'generated/gql';
import { startOfWeek, isEqual, differenceInDays, subDays, format } from 'date-fns';
import { Heading, Text, Box, Flex, Button } from 'rebass';
import { Activity } from 'types/activity';
import { toMinutes, getPace } from '../../helper/convert';
import { formatNumber } from '../../helper/format';
import Link from 'components/Link';
import Card from '../Card';

const ChartGrid = styled(Box)`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-row-gap: 3px;
  grid-template-areas: 'day1 day1distance' 'day2 day2distance' 'day3 day3distance' 'day4 day4distance' 'day5 day5distance' 'day6 day6distance' 'day7 day7distance';
`;

const Bar = styled(Box)`
  height: 8px;
  background: #49e0c5;
  box-shadow: 0px 0px 10px rgba(77, 206, 234, 0.2);
  border-radius: 8px;
  margin-top: 2px;
`;

interface ActivityGroup {
  activities: Activity[];
  distance: number;
  distanceUnit: DistanceUnit;
  duration: number;
}

const WeeklyMileage = () => {
  const now = new Date();
  const initialStartDate = startOfWeek(now);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [isPrevWeek, setPrevWeek] = useState(false);
  const daysPastStart = isPrevWeek ? 7 : differenceInDays(now, initialStartDate) + 1;
  const { loading, error, data } = useGetWeeklyActivityQuery({
    variables: { startDate: startDate.toISOString() },
  });
  if (error) return <Card title="Weekly summary">{error.message}</Card>;
  if (loading) return <Card title="Weekly summary">Loading ...</Card>;

  const fetchPreviousWeek = () => {
    setPrevWeek(true);
    const startDateClone = new Date(startDate.getTime());
    setStartDate(subDays(startDateClone, 7));
  };

  const activities = data?.weeklySummary || [];
  const totalDistance = activities.reduce((accumulator, currentValue) => currentValue.displayDistance + accumulator, 0);
  const totalDuration = activities.reduce((accumulator, currentValue) => currentValue.totalDuration + accumulator, 0);
  const distanceUnit = activities.length ? activities[0].displayUnit : DistanceUnit.Miles;
  let maxDailyDistance = 0;

  const groupedActivities = activities.reduce<Array<ActivityGroup>>((groups, activity) => {
    const index = new Date(activity.activityDate).getDay();
    if (groups[index]) {
      groups[index].activities.push(activity);
      groups[index].distance += activity.displayDistance;
      groups[index].duration += activity.totalDuration;
    } else {
      groups[index] = {
        activities: [activity],
        distance: activity.displayDistance,
        distanceUnit: activity.displayUnit,
        duration: activity.totalDuration,
      };
    }

    maxDailyDistance = Math.max(maxDailyDistance, groups[index].distance);

    return groups;
  }, []);

  return (
    <Card title={isPrevWeek ? `Week of ${format(startDate, 'MMM d')}` : 'This week'} sx={{ userSelect: 'none' }}>
      <Flex>
        <ChartGrid flex={1} mb={1}>
          <Heading sx={{ gridArea: 'day1' }} variant={'caps'}>
            S
          </Heading>
          <Heading sx={{ gridArea: 'day2' }} variant={'caps'}>
            M
          </Heading>
          <Heading sx={{ gridArea: 'day3' }} variant={'caps'}>
            T
          </Heading>
          <Heading sx={{ gridArea: 'day4' }} variant={'caps'}>
            W
          </Heading>
          <Heading sx={{ gridArea: 'day5' }} variant={'caps'}>
            T
          </Heading>
          <Heading sx={{ gridArea: 'day6' }} variant={'caps'}>
            F
          </Heading>
          <Heading sx={{ gridArea: 'day7' }} variant={'caps'}>
            S
          </Heading>
          {groupedActivities.map((group, index) => {
            const groupProportion = Number((group.distance / maxDailyDistance).toFixed(2));
            return (
              <Flex
                key={index}
                sx={{
                  gridArea: `day${index + 1}distance`,
                  width: groupProportion > 0 ? `${groupProportion * 100}%` : 0,
                }}
              >
                {group.activities.map((activity) => {
                  const activityProportion = Number((activity.displayDistance / group.distance).toFixed(2));
                  return (
                    <Tooltip
                      key={activity.id}
                      label={`${activity.title}${` `}|${` `}${formatNumber(
                        activity.displayDistance,
                        activity.displayUnit,
                      )}`}
                      style={{
                        background: 'hsla(0, 0%, 0%, 0.75)',
                        color: 'white',
                        fontFamily: 'HK Compakt',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '0.5em 1em',
                      }}
                    >
                      <Bar
                        mr={1}
                        sx={{
                          width: activityProportion > 0 ? `${activityProportion * 100}%` : 0,
                        }}
                      >
                        <Link to={`/activities/${activity.id}`} />
                      </Bar>
                    </Tooltip>
                  );
                })}
              </Flex>
            );
          })}
        </ChartGrid>
        <Flex flexDirection="column" sx={{ textAlign: 'right' }} flex={0.3}>
          <Box>
            <Heading mb={1} variant={'caps'}>
              Dist
            </Heading>
            <Text fontSize={3}>{formatNumber(totalDistance, distanceUnit)}</Text>
          </Box>

          <Box mt={3}>
            <Heading mb={1} variant={'caps'}>
              Time
            </Heading>
            <Text fontSize={3}>{toMinutes(totalDuration)}</Text>
          </Box>

          <Box mt={3}>
            <Heading mb={1} variant={'caps'}>
              Pace
            </Heading>
            <Text fontSize={3}>
              {getPace({
                units: distanceUnit,
                distance: totalDistance,
                duration: totalDuration,
              })}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex mt={2} justifyContent="space-between">
        <Text>
          {isPrevWeek ? `You averaged` : `You're averaging`} {formatNumber(totalDistance / daysPastStart, distanceUnit)}{' '}
          a day
        </Text>
      </Flex>
      <Flex mt={2} justifyContent="space-between">
        <Button
          variant="link"
          bg="transparent"
          fontWeight="500"
          mr={2}
          p={0}
          color="mutedText"
          sx={{
            textDecoration: 'underline',
          }}
          onClick={fetchPreviousWeek}
        >
          Previous week
        </Button>
        {!isEqual(startDate, initialStartDate) && (
          <Button
            variant="link"
            bg="transparent"
            fontWeight="500"
            color="mutedText"
            p={0}
            sx={{
              textDecoration: 'underline',
            }}
            onClick={() => {
              setPrevWeek(false);
              setStartDate(initialStartDate);
            }}
          >
            Back to current
          </Button>
        )}
      </Flex>
    </Card>
  );
};

export default WeeklyMileage;
