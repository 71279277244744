import React, { useState } from 'react';
import Shoe from './Shoe';
import { Flex, Box, Text, Button } from 'rebass';
import { Shoe as ShoeType, useGetShoesQuery, useUpdateShoeMutation } from 'generated/gql';
import Modal from './Modal';
import Card from './Card';
import ShoeEditForm from './Forms/ShoeEditForm';
import { useToasts } from 'react-toast-notifications';
import Link from './Link';

const ShoeList = () => {
  const [activeEditShoe, setActiveEditShoe] = useState<ShoeType | null>(null);
  const [showRetired, setShowRetired] = useState(false);
  const { loading, data } = useGetShoesQuery();
  const [updateShoe] = useUpdateShoeMutation();
  const { addToast } = useToasts();

  const closeEditModal = () => setActiveEditShoe(null);

  const onEditFormSubmit = (shoe: ShoeType) => async (values: ShoeType) => {
    try {
      const updateFields = {
        ...values,
        id: shoe.id,
      };
      const { data, errors } = await updateShoe({ variables: { input: updateFields } });

      if (!data || errors?.length) {
        const errorMessage = errors ? errors[0].message : 'Unknown error occurred!';
        addToast(errorMessage, { appearance: 'error' });
      } else {
        addToast(`Updated shoe ${data.updateShoe.name}`, { appearance: 'success' });
        closeEditModal();
      }
    } catch (error) {
      addToast(error.message, { appearance: 'error' });
    }
  };

  if (loading) return <p>Loading ...</p>;
  if (data && data.gear) {
    const activeShoes = data.gear.filter((shoe) => !shoe.isRetired);
    const retiredShoes = data.gear.filter((shoe) => shoe.isRetired);

    return (
      <Card title="My shoes">
        {activeShoes.map((shoe) => (
          <Box key={shoe.id} mb={2}>
            <Shoe shoe={shoe} editShoe={() => setActiveEditShoe(shoe)} />
          </Box>
        ))}
        {showRetired &&
          retiredShoes.map((shoe) => (
            <Box key={shoe.id} mb={2}>
              <Shoe shoe={shoe} editShoe={() => setActiveEditShoe(shoe)} />
            </Box>
          ))}
        <Flex flexDirection="column" alignItems="center">
          <Link sx={{ width: '100%' }} to="gear/new">
            <Button sx={{ width: '100%' }} variant="tertiary">
              Add a shoe
            </Button>
          </Link>
          {retiredShoes.length > 0 && (
            <Button variant="outline" mt={2} onClick={() => setShowRetired(!showRetired)}>
              {showRetired ? 'Hide retired' : 'See all'} shoes
            </Button>
          )}
        </Flex>
        <Modal isOpen={activeEditShoe !== null} onDismiss={closeEditModal}>
          {activeEditShoe ? (
            <ShoeEditForm shoe={activeEditShoe} onSubmit={onEditFormSubmit(activeEditShoe)} />
          ) : (
            'Unknown error. Please close modal and try again.'
          )}
        </Modal>
      </Card>
    );
  }
  return (
    <Flex flexDirection="column" justifyContent="flex-end">
      <Text>Add some shoes</Text>
    </Flex>
  );
};

export default ShoeList;
