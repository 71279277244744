import { WebAuth, Auth0DecodedHash, Auth0Error, LoginRequiredErrorCode, Auth0UserProfile } from 'auth0-js';
import { AUTH_TOKEN } from 'constant';
import { User } from 'types/user';
import { userInfo } from './apollo/local-state';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const CASSIDY_APP_DOMAIN = process.env.REACT_APP_APPLICATION_URL || '';
const CASSIDY_API_DOMAIN = process.env.REACT_APP_API_URL || '';

const auth0 = new WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: CLIENT_ID,
  // TODO: Investigate exact configuration of these optional properties. Copied from tutorial
  // https://auth0.com/blog/develop-modern-apps-with-react-graphql-apollo-and-add-authentication/#Secure-your-React-App
  audience: CASSIDY_API_DOMAIN,
  responseType: 'token id_token',
  scope: 'openid email',
});

/**
 * Creates a new user session by redirecting browser to our
 * hosted auth0 login page. The browser will be redirected to our
 * configured callback endpoint after authentication.
 */
export const login = () =>
  auth0.authorize({
    redirectUri: `${CASSIDY_APP_DOMAIN}/postAuth`,
    mode: 'login',
  });

export const signUp = () =>
  auth0.authorize({
    redirectUri: `${CASSIDY_APP_DOMAIN}/postAuth`,
    mode: 'signUp',
  });

/**
 * Clear the user session in auth0 and redirect back to Cassidy.
 */
export const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  userInfo({} as User);
  auth0.logout({
    returnTo: `${CASSIDY_APP_DOMAIN}/`,
    clientID: CLIENT_ID,
  });
};

/**
 * Parse the response of a login or checkSession with auth0.
 * @param error An Auth0Error. Will be null if auth completed successfully.
 * @param authResult The decoded auth result containing access and id tokens.
 */
const handleAuthResult = (error: Auth0Error | null, authResult: Auth0DecodedHash | null): User => {
  if (error) {
    if (error.code as LoginRequiredErrorCode) {
      // If the user logged in clear the token
      localStorage.removeItem(AUTH_TOKEN);
    }
    throw new Error(error.code);
  }

  if (authResult && authResult.idToken) {
    // The ID token is used to authenticate a user (i.e. ensure the user is known to our system)
    // It is a JWT encoded with a payload of the user's information.
    const { sub: id, email } = authResult.idTokenPayload as Auth0UserProfile;

    if (authResult.accessToken && authResult.expiresIn) {
      // The access token is used for authorization (i.e. ensure the user has permission to use our system)
      // Specifically, it provides access to the API resource
      localStorage.setItem(AUTH_TOKEN, authResult.accessToken);
      return {
        id,
        email,
      };
    }
  }

  // If there's no error object but the authResult object is not formatted correctly
  // then we have no idea what happened
  throw new Error('Unknown error');
};

/**
 * Extracts user session information from the response returned
 * by auth0.
 */
export const checkLoginResponse = (): Promise<User> =>
  new Promise((resolve, reject) => {
    auth0.parseHash((error, authResult) => {
      try {
        const result = handleAuthResult(error, authResult);
        return resolve(result);
      } catch (error) {
        return reject(error);
      }
    });
  });

export const getUserInfo = (accessToken: string): Promise<User> =>
  new Promise((resolve, reject) => {
    auth0.client.userInfo(accessToken, (error, result) => {
      if (error) {
        if (error.code as LoginRequiredErrorCode) {
          // If the user is not actually logged in (i.e. the token we passed was invalid),
          // clear the token and return an empty user
          localStorage.removeItem(AUTH_TOKEN);
        }
        return reject(new Error(error.code));
      }

      // Just to be safe, pick the properties we want from the user object
      const { sub: id, email } = result;
      return resolve({ id, email });
    });
  });
