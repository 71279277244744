import React, { ChangeEvent, FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';

interface NumberInputProps {
  name: string;
  label: string;
  defaultValue?: number;
  placeholder?: string;
  step?: string;
  required?: boolean;
}

const NumberInput: FunctionComponent<NumberInputProps> = ({
  name,
  label,
  placeholder,
  defaultValue = 0,
  step = '0.01',
  required = false,
}) => {
  return (
    <Box>
      <Text variant="label" mb={2} as={Label}>
        {label}
      </Text>
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field: { onChange, value, name: fieldName }, fieldState: { error } }) => {
          return (
            <>
              <Input
                type="number"
                placeholder={placeholder}
                step={step}
                name={fieldName}
                value={formatValue(value)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChange(parseFloat(event.target.value));
                }}
              />
              {error && (
                <Text mt={2} variant="error">
                  {error.message || 'Invalid distance.'}
                </Text>
              )}
            </>
          );
        }}
      />
    </Box>
  );
};

/**
 * Convert the value stored in state to a string that can be
 * passed to the DOM's input element.
 * @param value The value stored in the form's state.
 * @returns The value passed to the DOM's input element.
 */
function formatValue(value: any): string {
  if (typeof value === 'number') {
    if (Number.isNaN(value)) {
      return '';
    }
    // Trim to two decimal places if more than 2 decimal digits.
    // Otherwise, show the number as is
    if ((100 * value) % 1 !== 0) {
      return value.toFixed(2);
    }
  }
  return String(value);
}

export default NumberInput;
