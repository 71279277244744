import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/fonts/hk/font.css';
import './assets/fonts/gt/style.css';
import './assets/fonts/gimbo/shadow/gus.css';
import './assets/fonts/gimbo/ultra/gu.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'theme-ui';
import theme from './theme';
import { AnimatePresence } from 'framer-motion';

const ThemedApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence exitBeforeEnter>
        <App />
      </AnimatePresence>
    </ThemeProvider>
  );
};

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
