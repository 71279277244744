const theme = {
  initialColorModeName: 'default',
  useCustomProperties: true,
  useColorSchemeMediaQuery: true,
  fonts: {
    body: '"HK Compakt", system-ui, sans-serif',
    heading: '"GT America Expanded", sans-serif',
    wideHeading: '"GT America Extended", sans-serif',
    wideText: '"GT America Expanded", sans-serif',
    marketing: '"Gimbo Ultra", New York, system-ui, sans-serif',
    shadow: '"Gimbo Ultra Shadow", New York, system-ui, sans-serif',
    monospace: '"Office Code Pro", "Menlo", system-ui, sans-serif',
  },
  fontSizes: [11, 12, 13, 15, 18, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 500,
    black: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    caps: '1.5px',
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
      '&:hover': {
        color: 'secondary',
      },
    },
    hr: {
      height: '1px',
      opacity: '50%',
      color: 'darkerBackground',
    },
  },
  root: {
    fontFamily: 'body',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    },
    wideText: {
      fontFamily: 'wideText',
    },
    display: {
      fontFamily: 'wideHeading',
      fontWeight: 'black',
      lineHeight: 'heading',
      letterSpacing: 'heading',
      fontSize: [4, 4, 5],
    },
    marketingHeader: {
      variant: 'text.display',
      fontFamily: 'marketing',
      fontWeight: '400',
      fontSize: [44, 48, 48, 72],
    },
    marketingHeaderShadow: {
      variant: 'text.display',
      fontFamily: 'shadow',
      fontWeight: '400',
      fontSize: [44, 48, 48, 72],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: 'caps',
      fontWeight: 'heading',
      fontFamily: 'monospace',
      color: 'muted',
      fontSize: [0, 0, 0],
    },
    caption: {
      fontSize: [1, 1, 1],
      fontFamily: 'body',
    },
    label: {
      fontSize: [1, 1, 1],
      fontFamily: 'body',
      color: 'muted',
    },
    error: {
      variant: 'text.caption',
      color: 'red',
    },
  },
  buttons: {
    marketing: {
      padding: `12px 16px`,
      color: 'background',
      lineHeight: 1.2,
      fontFamily: 'wideHeading',
      fontSize: ['18px', 3, 3, 4],
      fontWeight: '700',
      cursor: 'pointer',
      borderRadius: '1px',
      bg: 'primary',
    },
    marketingLink: {
      padding: `12px`,
      variant: 'buttons.marketing',
      color: 'primary',
      bg: 'background',
    },
    primary: {
      borderRadius: '16px',
      height: 32,
      color: 'background',
      lineHeight: 1.15,
      fontSize: 14,
      fontFamily: 'body',
      fontWeight: '400',
      cursor: 'pointer',
      bg: 'primary',
    },
    link: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'background',
      height: 'unset',
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary',
    },
    tertiary: {
      variant: 'buttons.primary',
      padding: `2px 8px`,
      color: 'black',
      bg: 'lightGray',
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
    strava: {
      variant: 'buttons.primary',
      bg: 'strava',
      border: `1px solid`,
      borderColor: 'lightGray',
      color: 'primary',
    },
  },
  shadows: {
    card: '0 1px 3px rgba(0, 0, 0, .05)',
  },
  variants: {
    card: {
      p: 3,
      bg: 'lighterBackground',
      borderRadius: 1,
      boxShadow: 'card',
    },
    link: {
      color: 'primary',
      ':visited': {
        color: 'primary',
      },
    },
    nav: {
      fontSize: 2,
      fontWeight: 'bold',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },
  colors: {
    text: '#222222',
    mutedText: '#444444',
    background: '#f6f6f6',
    lighterBackground: '#fff',
    darkerBackground: '#d9d9d9',
    primary: '#000',
    secondary: '#33e',
    muted: '#909090',
    strava: '#fff',
    textAccent: '#EF5858',
    backgroundAccent: '#f1dca6',
    textAccentShadow: '#000',
    modes: {
      dark: {
        text: 'hsl(210, 50%, 96%)',
        mutedText: '#757da9',
        background: '#252837',
        lighterBackground: 'hsl(230, 25%, 25%)',
        darkerBackground: '#252837',
        primary: '#fff',
        secondary: 'hsl(290, 100%, 80%)',
        highlight: 'hsl(260, 20%, 40%)',
        purple: 'hsl(290, 100%, 80%)',
        muted: '#757da9',
        strava: '#757da9',
        gray: 'hsl(210, 50%, 60%)',
        textAccent: '#49e0c5',
        backgroundAccent: '#f1dca6',
        textAccentShadow: '#000',
      },
    },
  },
};

export default theme;
