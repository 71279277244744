import React, { FunctionComponent } from 'react';
import { Box, Text } from 'rebass';
import { Select, Label } from '@rebass/forms';
import { DistanceUnit, useGetShoesQuery } from 'generated/gql';
import { toMiles } from 'helper/convert';
import { formatNumber } from 'helper/format';
import { Controller, useFormContext } from 'react-hook-form';

const NO_SHOE_SELECTED = 'NO_SHOE_SELECTED';

const ShoeSelectInput: FunctionComponent = () => {
  const { control } = useFormContext();
  const { loading, data } = useGetShoesQuery();

  const activeShoes = data && data.gear ? data.gear.filter((shoe) => !shoe.isRetired) : [];

  const getInternalValue = (value: string | null) => {
    // Null & undefined don't work well as select values, so use
    // a constant instead
    return value || NO_SHOE_SELECTED;
  };

  const getExternalValue = (value: string) => {
    // Keep the constant internal to this component by passing
    // null when setting the form value
    return value === NO_SHOE_SELECTED ? null : value;
  };

  return (
    <Controller
      control={control}
      name="shoe.id"
      render={({ field: { onChange, value } }) => (
        <Box mb={[2, 4, 4]}>
          <Text as={Label} htmlFor="shoe" variant="label" mb={2}>
            Shoe
          </Text>
          <Select
            id="shoe"
            name="shoe.id"
            value={getInternalValue(value)}
            onChange={(e: any) => {
              onChange(getExternalValue(e.target.value));
            }}
          >
            {activeShoes.map((shoe) => (
              <option key={shoe.id} value={shoe.id}>{`${shoe.name || shoe.brand + ' ' + shoe.model} - ${formatNumber(
                toMiles(shoe.distance),
                DistanceUnit.Miles,
              )}`}</option>
            ))}
            <option value={NO_SHOE_SELECTED}>None</option>
            {loading && <option disabled>Loading shoes...</option>}
          </Select>
        </Box>
      )}
    />
  );
};

export default ShoeSelectInput;
