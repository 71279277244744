import React, { Fragment, FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'rebass';
import { Label, Select } from '@rebass/forms';

interface SelectInputProps {
  name: string;
  label: string;
  options: Array<{ id: string; label?: string }>;
  errorMessage?: string;
  required?: boolean;
}

const SelectInput: FunctionComponent<SelectInputProps> = ({ label, name, options, errorMessage, required }) => {
  const defaultErrorMessage = errorMessage || (required && `${label} is required`);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Fragment>
      <Text as={Label} htmlFor={name} variant="label" mb={2}>
        {label}
      </Text>
      <Select {...register(name, { required })}>
        {options.map(({ id, label }) => {
          return (
            <option key={id} value={id}>
              {label || id}
            </option>
          );
        })}
      </Select>
      {errors && (
        <Text mt={2} variant="error">
          {errors && errors[name] && (errors[name].message || defaultErrorMessage)}
        </Text>
      )}
    </Fragment>
  );
};

export default SelectInput;
