/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A string containing a number that is potentially larger than 32 bits (maximum size for an integer) */
  BigInt: any;
};


export type Activity = {
  __typename?: 'Activity';
  activityDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayDistance: Scalars['Float'];
  displayUnit: DistanceUnit;
  id: Scalars['ID'];
  intervals: Array<Interval>;
  shoe?: Maybe<Shoe>;
  stravaActivity?: Maybe<StravaActivity>;
  title: Scalars['String'];
  totalDistance: Scalars['Float'];
  totalDuration: Scalars['Int'];
  type: ActivityType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  workoutType?: Maybe<WorkoutType>;
};

export type ActivityCreateInput = {
  activityDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  intervals?: Maybe<Array<IntervalCreateInput>>;
  shoe?: Maybe<ActivityShoeInput>;
  title: Scalars['String'];
  type: ActivityType;
  workoutType?: Maybe<WorkoutType>;
};

export type ActivityShoeInput = {
  /** The id of an existing shoe. */
  id: Scalars['ID'];
};

export type ActivityTrendsSummary = {
  __typename?: 'ActivityTrendsSummary';
  monthlyDistance: TrendSummary;
  weeklyDistance: TrendSummary;
};

export const ActivityType = {
  Bike: 'BIKE',
  Other: 'OTHER',
  Run: 'RUN',
  Swim: 'SWIM'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];
export type ActivityUpdateInput = {
  activityDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intervals?: Maybe<Array<IntervalCreateInput>>;
  /** The shoe linked to this activity. To remove the linked shoe, provide an object with a null id */
  shoe?: Maybe<ActivityUpdateShoeInput>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ActivityType>;
  workoutType?: Maybe<WorkoutType>;
};

export type ActivityUpdateShoeInput = {
  /** The id of an existing shoe. */
  id?: Maybe<Scalars['ID']>;
};



export const DistanceUnit = {
  Kilometers: 'KILOMETERS',
  Meters: 'METERS',
  Miles: 'MILES'
} as const;

export type DistanceUnit = typeof DistanceUnit[keyof typeof DistanceUnit];
export type Interval = {
  __typename?: 'Interval';
  activity: Activity;
  createdAt: Scalars['DateTime'];
  displayDistance: Scalars['Float'];
  displayUnit: DistanceUnit;
  distance: Scalars['Float'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  type: IntervalType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Parameters for creating an interval as part of an activity. */
export type IntervalCreateInput = {
  /** Distance of an interval expressed in terms of displayUnit. */
  displayDistance?: Maybe<Scalars['Float']>;
  /** Unit in which distance is expressed when displayed. DisplayDistance shows the converted distance. */
  displayUnit?: Maybe<DistanceUnit>;
  /** Distance of an interval, always in meters. Either distance or displayDistance & displayUnit are required. */
  distance?: Maybe<Scalars['Float']>;
  /** Duration of the interval, always in seconds. */
  duration: Scalars['Int'];
  type?: Maybe<IntervalType>;
};

export const IntervalType = {
  Cooldown: 'COOLDOWN',
  Default: 'DEFAULT',
  Race: 'RACE',
  Rest: 'REST',
  Warmup: 'WARMUP',
  Workout: 'WORKOUT'
} as const;

export type IntervalType = typeof IntervalType[keyof typeof IntervalType];
export type Mutation = {
  __typename?: 'Mutation';
  connectStrava: User;
  createActivity: Activity;
  createShoe: Shoe;
  deleteActivity: Activity;
  deleteShoe: Shoe;
  importStravaActivity: Activity;
  updateActivity: Activity;
  updateShoe: Shoe;
  updateUserPreferences: User;
  webhookImportStravaActivity: Activity;
};


export type MutationConnectStravaArgs = {
  code: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
};


export type MutationCreateActivityArgs = {
  input: ActivityCreateInput;
};


export type MutationCreateShoeArgs = {
  input: ShoeCreateInput;
};


export type MutationDeleteActivityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteShoeArgs = {
  id: Scalars['ID'];
};


export type MutationImportStravaActivityArgs = {
  activityId: Scalars['BigInt'];
};


export type MutationUpdateActivityArgs = {
  input: ActivityUpdateInput;
};


export type MutationUpdateShoeArgs = {
  input: ShoeUpdateInput;
};


export type MutationUpdateUserPreferencesArgs = {
  preferences: Array<UserPreferenceInput>;
};


export type MutationWebhookImportStravaActivityArgs = {
  activityId: Scalars['BigInt'];
  athleteId: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  activity: Activity;
  activityTrends: ActivityTrendsSummary;
  feed: Array<Activity>;
  gear: Array<Shoe>;
  isAuthenticated: Scalars['Boolean'];
  me: User;
  shoe: Shoe;
  weeklySummary: Array<Activity>;
};


export type QueryActivityArgs = {
  id: Scalars['ID'];
};


export type QueryActivityTrendsArgs = {
  endDate: Scalars['DateTime'];
};


export type QueryFeedArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryShoeArgs = {
  id: Scalars['ID'];
};


export type QueryWeeklySummaryArgs = {
  startDate: Scalars['DateTime'];
};

export type Shoe = {
  __typename?: 'Shoe';
  activities?: Maybe<Array<Maybe<Activity>>>;
  brand: ShoeBrand;
  createdAt: Scalars['DateTime'];
  distance: Scalars['Float'];
  id: Scalars['ID'];
  isRetired: Scalars['Boolean'];
  model: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export const ShoeBrand = {
  Adidas: 'ADIDAS',
  Altra: 'ALTRA',
  Arcteryx: 'ARCTERYX',
  Asics: 'ASICS',
  Brooks: 'BROOKS',
  Hokaoneone: 'HOKAONEONE',
  Inov8: 'INOV8',
  Lasportiva: 'LASPORTIVA',
  Merrell: 'MERRELL',
  Mizuno: 'MIZUNO',
  Newbalance: 'NEWBALANCE',
  Newton: 'NEWTON',
  Nike: 'NIKE',
  On: 'ON',
  Other: 'OTHER',
  Puma: 'PUMA',
  Reebok: 'REEBOK',
  Salomon: 'SALOMON',
  Saucony: 'SAUCONY',
  Skechersperformance: 'SKECHERSPERFORMANCE',
  Thenorthface: 'THENORTHFACE',
  Topoathletic: 'TOPOATHLETIC',
  Underarmour: 'UNDERARMOUR'
} as const;

export type ShoeBrand = typeof ShoeBrand[keyof typeof ShoeBrand];
export type ShoeCreateInput = {
  brand: ShoeBrand;
  distance?: Maybe<Scalars['Float']>;
  isRetired?: Maybe<Scalars['Boolean']>;
  model: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type ShoeUpdateInput = {
  brand?: Maybe<ShoeBrand>;
  distance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isRetired?: Maybe<Scalars['Boolean']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type StravaActivity = {
  __typename?: 'StravaActivity';
  polyline?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type StravaUserInfo = {
  __typename?: 'StravaUserInfo';
  profileUrl: Scalars['String'];
};

export type TrendSummary = {
  __typename?: 'TrendSummary';
  current: Scalars['Float'];
  percentChange?: Maybe<Scalars['Float']>;
  previous: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isStravaAuthenticated: Scalars['Boolean'];
  preferences: Array<UserPreference>;
  strava?: Maybe<StravaUserInfo>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  key: UserPreferenceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  value: Scalars['String'];
};

export type UserPreferenceInput = {
  id?: Maybe<Scalars['Int']>;
  key: UserPreferenceType;
  value: Scalars['String'];
};

export const UserPreferenceType = {
  DisplayDistanceUnit: 'DisplayDistanceUnit',
  ShoeSize: 'ShoeSize'
} as const;

export type UserPreferenceType = typeof UserPreferenceType[keyof typeof UserPreferenceType];
export const WorkoutType = {
  Default: 'DEFAULT',
  Other: 'OTHER',
  Tempo: 'TEMPO',
  Workout: 'WORKOUT'
} as const;

export type WorkoutType = typeof WorkoutType[keyof typeof WorkoutType];
export type DefaultShoeFragmentFragment = (
  { __typename?: 'Shoe' }
  & Pick<Shoe, 'id' | 'userId' | 'brand' | 'model' | 'name' | 'distance' | 'isRetired' | 'rating' | 'createdAt' | 'updatedAt'>
);

export type DefaultActivityFragmentFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'title' | 'description' | 'displayDistance' | 'displayUnit' | 'totalDuration' | 'activityDate' | 'type' | 'workoutType'>
  & { intervals: Array<(
    { __typename?: 'Interval' }
    & Pick<Interval, 'displayDistance' | 'displayUnit' | 'duration' | 'type'>
  )>, shoe?: Maybe<(
    { __typename?: 'Shoe' }
    & DefaultShoeFragmentFragment
  )>, stravaActivity?: Maybe<(
    { __typename?: 'StravaActivity' }
    & Pick<StravaActivity, 'url' | 'polyline'>
  )> }
);

export type AddShoeMutationVariables = Exact<{
  input: ShoeCreateInput;
}>;


export type AddShoeMutation = (
  { __typename?: 'Mutation' }
  & { createShoe: (
    { __typename?: 'Shoe' }
    & DefaultShoeFragmentFragment
  ) }
);

export type UpdateShoeMutationVariables = Exact<{
  input: ShoeUpdateInput;
}>;


export type UpdateShoeMutation = (
  { __typename?: 'Mutation' }
  & { updateShoe: (
    { __typename?: 'Shoe' }
    & DefaultShoeFragmentFragment
  ) }
);

export type AddActivityMutationVariables = Exact<{
  input: ActivityCreateInput;
}>;


export type AddActivityMutation = (
  { __typename?: 'Mutation' }
  & { createActivity: (
    { __typename?: 'Activity' }
    & DefaultActivityFragmentFragment
  ) }
);

export type ImportStravaActivityMutationVariables = Exact<{
  activityId: Scalars['BigInt'];
}>;


export type ImportStravaActivityMutation = (
  { __typename?: 'Mutation' }
  & { importStravaActivity: (
    { __typename?: 'Activity' }
    & DefaultActivityFragmentFragment
  ) }
);

export type UpdateActivityMutationVariables = Exact<{
  input: ActivityUpdateInput;
}>;


export type UpdateActivityMutation = (
  { __typename?: 'Mutation' }
  & { updateActivity: (
    { __typename?: 'Activity' }
    & DefaultActivityFragmentFragment
  ) }
);

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteActivityMutation = (
  { __typename?: 'Mutation' }
  & { deleteActivity: (
    { __typename?: 'Activity' }
    & Pick<Activity, 'title'>
  ) }
);

export type ConnectStravaMutationVariables = Exact<{
  code: Scalars['String'];
  scope: Scalars['String'];
}>;


export type ConnectStravaMutation = (
  { __typename?: 'Mutation' }
  & { connectStrava: (
    { __typename?: 'User' }
    & Pick<User, 'isStravaAuthenticated'>
  ) }
);

export type GetShoesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShoesQuery = (
  { __typename?: 'Query' }
  & { gear: Array<(
    { __typename?: 'Shoe' }
    & DefaultShoeFragmentFragment
  )> }
);

export type GetActivityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetActivityQuery = (
  { __typename?: 'Query' }
  & { activity: (
    { __typename?: 'Activity' }
    & DefaultActivityFragmentFragment
  ) }
);

export type GetWeeklyActivityQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
}>;


export type GetWeeklyActivityQuery = (
  { __typename?: 'Query' }
  & { weeklySummary: Array<(
    { __typename?: 'Activity' }
    & DefaultActivityFragmentFragment
  )> }
);

export type FeedQueryQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FeedQueryQuery = (
  { __typename?: 'Query' }
  & { feed: Array<(
    { __typename?: 'Activity' }
    & DefaultActivityFragmentFragment
  )> }
);

export type GetActivityTrendsQueryVariables = Exact<{
  endDate: Scalars['DateTime'];
}>;


export type GetActivityTrendsQuery = (
  { __typename?: 'Query' }
  & { activityTrends: (
    { __typename?: 'ActivityTrendsSummary' }
    & { weeklyDistance: (
      { __typename?: 'TrendSummary' }
      & TrendSummaryFragment
    ), monthlyDistance: (
      { __typename?: 'TrendSummary' }
      & TrendSummaryFragment
    ) }
  ) }
);

export type TrendSummaryFragment = (
  { __typename?: 'TrendSummary' }
  & Pick<TrendSummary, 'current' | 'previous' | 'percentChange'>
);

export type IsStravaConnectedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsStravaConnectedQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { strava?: Maybe<(
      { __typename?: 'StravaUserInfo' }
      & Pick<StravaUserInfo, 'profileUrl'>
    )> }
  ) }
);

export type IsAuthenticatedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsAuthenticatedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAuthenticated'>
);

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export const DefaultShoeFragmentFragmentDoc = gql`
    fragment DefaultShoeFragment on Shoe {
  id
  userId
  brand
  model
  name
  distance
  isRetired
  rating
  createdAt
  updatedAt
}
    `;
export const DefaultActivityFragmentFragmentDoc = gql`
    fragment DefaultActivityFragment on Activity {
  id
  title
  description
  displayDistance
  displayUnit
  totalDuration
  activityDate
  type
  workoutType
  intervals {
    displayDistance
    displayUnit
    duration
    type
  }
  shoe {
    ...DefaultShoeFragment
  }
  stravaActivity {
    url
    polyline
  }
}
    ${DefaultShoeFragmentFragmentDoc}`;
export const TrendSummaryFragmentDoc = gql`
    fragment trendSummary on TrendSummary {
  current
  previous
  percentChange
}
    `;
export const AddShoeDocument = gql`
    mutation addShoe($input: ShoeCreateInput!) {
  createShoe(input: $input) {
    ...DefaultShoeFragment
  }
}
    ${DefaultShoeFragmentFragmentDoc}`;
export type AddShoeMutationFn = Apollo.MutationFunction<AddShoeMutation, AddShoeMutationVariables>;

/**
 * __useAddShoeMutation__
 *
 * To run a mutation, you first call `useAddShoeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShoeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShoeMutation, { data, loading, error }] = useAddShoeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShoeMutation(baseOptions?: Apollo.MutationHookOptions<AddShoeMutation, AddShoeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddShoeMutation, AddShoeMutationVariables>(AddShoeDocument, options);
      }
export type AddShoeMutationHookResult = ReturnType<typeof useAddShoeMutation>;
export type AddShoeMutationResult = Apollo.MutationResult<AddShoeMutation>;
export type AddShoeMutationOptions = Apollo.BaseMutationOptions<AddShoeMutation, AddShoeMutationVariables>;
export const UpdateShoeDocument = gql`
    mutation updateShoe($input: ShoeUpdateInput!) {
  updateShoe(input: $input) {
    ...DefaultShoeFragment
  }
}
    ${DefaultShoeFragmentFragmentDoc}`;
export type UpdateShoeMutationFn = Apollo.MutationFunction<UpdateShoeMutation, UpdateShoeMutationVariables>;

/**
 * __useUpdateShoeMutation__
 *
 * To run a mutation, you first call `useUpdateShoeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShoeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShoeMutation, { data, loading, error }] = useUpdateShoeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShoeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShoeMutation, UpdateShoeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShoeMutation, UpdateShoeMutationVariables>(UpdateShoeDocument, options);
      }
export type UpdateShoeMutationHookResult = ReturnType<typeof useUpdateShoeMutation>;
export type UpdateShoeMutationResult = Apollo.MutationResult<UpdateShoeMutation>;
export type UpdateShoeMutationOptions = Apollo.BaseMutationOptions<UpdateShoeMutation, UpdateShoeMutationVariables>;
export const AddActivityDocument = gql`
    mutation addActivity($input: ActivityCreateInput!) {
  createActivity(input: $input) {
    ...DefaultActivityFragment
  }
}
    ${DefaultActivityFragmentFragmentDoc}`;
export type AddActivityMutationFn = Apollo.MutationFunction<AddActivityMutation, AddActivityMutationVariables>;

/**
 * __useAddActivityMutation__
 *
 * To run a mutation, you first call `useAddActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityMutation, { data, loading, error }] = useAddActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddActivityMutation, AddActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivityMutation, AddActivityMutationVariables>(AddActivityDocument, options);
      }
export type AddActivityMutationHookResult = ReturnType<typeof useAddActivityMutation>;
export type AddActivityMutationResult = Apollo.MutationResult<AddActivityMutation>;
export type AddActivityMutationOptions = Apollo.BaseMutationOptions<AddActivityMutation, AddActivityMutationVariables>;
export const ImportStravaActivityDocument = gql`
    mutation importStravaActivity($activityId: BigInt!) {
  importStravaActivity(activityId: $activityId) {
    ...DefaultActivityFragment
  }
}
    ${DefaultActivityFragmentFragmentDoc}`;
export type ImportStravaActivityMutationFn = Apollo.MutationFunction<ImportStravaActivityMutation, ImportStravaActivityMutationVariables>;

/**
 * __useImportStravaActivityMutation__
 *
 * To run a mutation, you first call `useImportStravaActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportStravaActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importStravaActivityMutation, { data, loading, error }] = useImportStravaActivityMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useImportStravaActivityMutation(baseOptions?: Apollo.MutationHookOptions<ImportStravaActivityMutation, ImportStravaActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportStravaActivityMutation, ImportStravaActivityMutationVariables>(ImportStravaActivityDocument, options);
      }
export type ImportStravaActivityMutationHookResult = ReturnType<typeof useImportStravaActivityMutation>;
export type ImportStravaActivityMutationResult = Apollo.MutationResult<ImportStravaActivityMutation>;
export type ImportStravaActivityMutationOptions = Apollo.BaseMutationOptions<ImportStravaActivityMutation, ImportStravaActivityMutationVariables>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($input: ActivityUpdateInput!) {
  updateActivity(input: $input) {
    ...DefaultActivityFragment
  }
}
    ${DefaultActivityFragmentFragmentDoc}`;
export type UpdateActivityMutationFn = Apollo.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, options);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<UpdateActivityMutation, UpdateActivityMutationVariables>;
export const DeleteActivityDocument = gql`
    mutation deleteActivity($id: ID!) {
  deleteActivity(id: $id) {
    title
  }
}
    `;
export type DeleteActivityMutationFn = Apollo.MutationFunction<DeleteActivityMutation, DeleteActivityMutationVariables>;

/**
 * __useDeleteActivityMutation__
 *
 * To run a mutation, you first call `useDeleteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityMutation, { data, loading, error }] = useDeleteActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DeleteActivityDocument, options);
      }
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export type DeleteActivityMutationOptions = Apollo.BaseMutationOptions<DeleteActivityMutation, DeleteActivityMutationVariables>;
export const ConnectStravaDocument = gql`
    mutation connectStrava($code: String!, $scope: String!) {
  connectStrava(code: $code, scope: $scope) {
    isStravaAuthenticated
  }
}
    `;
export type ConnectStravaMutationFn = Apollo.MutationFunction<ConnectStravaMutation, ConnectStravaMutationVariables>;

/**
 * __useConnectStravaMutation__
 *
 * To run a mutation, you first call `useConnectStravaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectStravaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectStravaMutation, { data, loading, error }] = useConnectStravaMutation({
 *   variables: {
 *      code: // value for 'code'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useConnectStravaMutation(baseOptions?: Apollo.MutationHookOptions<ConnectStravaMutation, ConnectStravaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectStravaMutation, ConnectStravaMutationVariables>(ConnectStravaDocument, options);
      }
export type ConnectStravaMutationHookResult = ReturnType<typeof useConnectStravaMutation>;
export type ConnectStravaMutationResult = Apollo.MutationResult<ConnectStravaMutation>;
export type ConnectStravaMutationOptions = Apollo.BaseMutationOptions<ConnectStravaMutation, ConnectStravaMutationVariables>;
export const GetShoesDocument = gql`
    query getShoes {
  gear {
    ...DefaultShoeFragment
  }
}
    ${DefaultShoeFragmentFragmentDoc}`;

/**
 * __useGetShoesQuery__
 *
 * To run a query within a React component, call `useGetShoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShoesQuery(baseOptions?: Apollo.QueryHookOptions<GetShoesQuery, GetShoesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShoesQuery, GetShoesQueryVariables>(GetShoesDocument, options);
      }
export function useGetShoesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShoesQuery, GetShoesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShoesQuery, GetShoesQueryVariables>(GetShoesDocument, options);
        }
export type GetShoesQueryHookResult = ReturnType<typeof useGetShoesQuery>;
export type GetShoesLazyQueryHookResult = ReturnType<typeof useGetShoesLazyQuery>;
export type GetShoesQueryResult = Apollo.QueryResult<GetShoesQuery, GetShoesQueryVariables>;
export const GetActivityDocument = gql`
    query getActivity($id: ID!) {
  activity(id: $id) {
    ...DefaultActivityFragment
  }
}
    ${DefaultActivityFragmentFragmentDoc}`;

/**
 * __useGetActivityQuery__
 *
 * To run a query within a React component, call `useGetActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityQuery(baseOptions: Apollo.QueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<GetActivityQuery, GetActivityQueryVariables>;
export const GetWeeklyActivityDocument = gql`
    query getWeeklyActivity($startDate: DateTime!) {
  weeklySummary(startDate: $startDate) {
    ...DefaultActivityFragment
  }
}
    ${DefaultActivityFragmentFragmentDoc}`;

/**
 * __useGetWeeklyActivityQuery__
 *
 * To run a query within a React component, call `useGetWeeklyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyActivityQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetWeeklyActivityQuery(baseOptions: Apollo.QueryHookOptions<GetWeeklyActivityQuery, GetWeeklyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeeklyActivityQuery, GetWeeklyActivityQueryVariables>(GetWeeklyActivityDocument, options);
      }
export function useGetWeeklyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeeklyActivityQuery, GetWeeklyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeeklyActivityQuery, GetWeeklyActivityQueryVariables>(GetWeeklyActivityDocument, options);
        }
export type GetWeeklyActivityQueryHookResult = ReturnType<typeof useGetWeeklyActivityQuery>;
export type GetWeeklyActivityLazyQueryHookResult = ReturnType<typeof useGetWeeklyActivityLazyQuery>;
export type GetWeeklyActivityQueryResult = Apollo.QueryResult<GetWeeklyActivityQuery, GetWeeklyActivityQueryVariables>;
export const FeedQueryDocument = gql`
    query FeedQuery($skip: Int = 0, $take: Int = 10) {
  feed(skip: $skip, take: $take) {
    ...DefaultActivityFragment
  }
}
    ${DefaultActivityFragmentFragmentDoc}`;

/**
 * __useFeedQueryQuery__
 *
 * To run a query within a React component, call `useFeedQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedQueryQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFeedQueryQuery(baseOptions?: Apollo.QueryHookOptions<FeedQueryQuery, FeedQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedQueryQuery, FeedQueryQueryVariables>(FeedQueryDocument, options);
      }
export function useFeedQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedQueryQuery, FeedQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedQueryQuery, FeedQueryQueryVariables>(FeedQueryDocument, options);
        }
export type FeedQueryQueryHookResult = ReturnType<typeof useFeedQueryQuery>;
export type FeedQueryLazyQueryHookResult = ReturnType<typeof useFeedQueryLazyQuery>;
export type FeedQueryQueryResult = Apollo.QueryResult<FeedQueryQuery, FeedQueryQueryVariables>;
export const GetActivityTrendsDocument = gql`
    query getActivityTrends($endDate: DateTime!) {
  activityTrends(endDate: $endDate) {
    weeklyDistance {
      ...trendSummary
    }
    monthlyDistance {
      ...trendSummary
    }
  }
}
    ${TrendSummaryFragmentDoc}`;

/**
 * __useGetActivityTrendsQuery__
 *
 * To run a query within a React component, call `useGetActivityTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityTrendsQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetActivityTrendsQuery(baseOptions: Apollo.QueryHookOptions<GetActivityTrendsQuery, GetActivityTrendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityTrendsQuery, GetActivityTrendsQueryVariables>(GetActivityTrendsDocument, options);
      }
export function useGetActivityTrendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityTrendsQuery, GetActivityTrendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityTrendsQuery, GetActivityTrendsQueryVariables>(GetActivityTrendsDocument, options);
        }
export type GetActivityTrendsQueryHookResult = ReturnType<typeof useGetActivityTrendsQuery>;
export type GetActivityTrendsLazyQueryHookResult = ReturnType<typeof useGetActivityTrendsLazyQuery>;
export type GetActivityTrendsQueryResult = Apollo.QueryResult<GetActivityTrendsQuery, GetActivityTrendsQueryVariables>;
export const IsStravaConnectedDocument = gql`
    query isStravaConnected {
  me {
    strava {
      profileUrl
    }
  }
}
    `;

/**
 * __useIsStravaConnectedQuery__
 *
 * To run a query within a React component, call `useIsStravaConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsStravaConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsStravaConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsStravaConnectedQuery(baseOptions?: Apollo.QueryHookOptions<IsStravaConnectedQuery, IsStravaConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsStravaConnectedQuery, IsStravaConnectedQueryVariables>(IsStravaConnectedDocument, options);
      }
export function useIsStravaConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsStravaConnectedQuery, IsStravaConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsStravaConnectedQuery, IsStravaConnectedQueryVariables>(IsStravaConnectedDocument, options);
        }
export type IsStravaConnectedQueryHookResult = ReturnType<typeof useIsStravaConnectedQuery>;
export type IsStravaConnectedLazyQueryHookResult = ReturnType<typeof useIsStravaConnectedLazyQuery>;
export type IsStravaConnectedQueryResult = Apollo.QueryResult<IsStravaConnectedQuery, IsStravaConnectedQueryVariables>;
export const IsAuthenticatedDocument = gql`
    query isAuthenticated {
  isAuthenticated @client
}
    `;

/**
 * __useIsAuthenticatedQuery__
 *
 * To run a query within a React component, call `useIsAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAuthenticatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsAuthenticatedQuery(baseOptions?: Apollo.QueryHookOptions<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>(IsAuthenticatedDocument, options);
      }
export function useIsAuthenticatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>(IsAuthenticatedDocument, options);
        }
export type IsAuthenticatedQueryHookResult = ReturnType<typeof useIsAuthenticatedQuery>;
export type IsAuthenticatedLazyQueryHookResult = ReturnType<typeof useIsAuthenticatedLazyQuery>;
export type IsAuthenticatedQueryResult = Apollo.QueryResult<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>;
export const UserProfileDocument = gql`
    query userProfile {
  me {
    email @client
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;