import React from 'react';
import { Text, Flex, Box, Card as RebassCard, SxProps } from 'rebass';
import { Divider } from 'theme-ui';

interface CardProps {
  title?: string;
  sx?: SxProps;
  padded?: boolean;
}

const Card: React.FunctionComponent<CardProps> = ({ children, title, sx, padded = true }) => {
  return (
    <RebassCard sx={{ borderRadius: '10px', padding: 0, ...sx }}>
      {title && (
        <>
          <Flex px={3} pt={3} justifyContent="flex-start" alignItems="center">
            <Text>{title}</Text>
          </Flex>
          <Divider sx={{ opacity: 1 }} />
        </>
      )}
      <Box p={padded ? 3 : 0}>{children}</Box>
    </RebassCard>
  );
};

export default Card;
