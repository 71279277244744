import React, { FunctionComponent } from 'react';
import { DistanceUnit, Shoe } from 'generated/gql';
import { Heading, Text, Box, Flex } from 'rebass';
import { formatNumber } from 'helper/format';
import { getPace, toMinutes } from 'helper/convert';

interface ActivityStatsProps {
  distance: number;
  distanceUnit: DistanceUnit;
  duration: number;
  shoe?: Shoe | null;
}

const ActivityStats: FunctionComponent<ActivityStatsProps> = ({ distance, distanceUnit, duration, shoe }) => {
  return (
    <Flex pb={3} px={3}>
      <Box>
        <Heading mb={1} variant="caps">
          Dist
        </Heading>
        <Text fontSize={[2, 3, 4]}>{formatNumber(distance, distanceUnit)}</Text>
      </Box>

      <Box ml={4}>
        <Heading mb={1} variant="caps">
          Time
        </Heading>
        <Text fontSize={[2, 3, 4]}>{toMinutes(duration)}</Text>
      </Box>

      <Box ml={4}>
        <Heading mb={1} variant="caps">
          Pace
        </Heading>
        <Text fontSize={[2, 3, 4]}>
          {getPace({
            units: distanceUnit,
            duration,
            distance,
          })}
        </Text>
      </Box>

      {shoe && (
        <Box ml={4}>
          <Heading mb={1} variant="caps">
            Shoe
          </Heading>
          <Text>{shoe.name || `${shoe.brand} ${shoe.model}`}</Text>
        </Box>
      )}
    </Flex>
  );
};

export default ActivityStats;
