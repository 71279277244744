import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import { Box } from 'rebass';
import ShoeList from '../components/ShoeList';
import WeeklyMileage from '../components/Graphs/WeeklyMileage';
import AddStravaWidget from '../components/AddStravaWidget';
import ActivityTrends from 'components/ActivityTrends';

const LayoutBox = styled(Box)`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr 0.8fr;
  grid-column-gap: 24px;
  grid-area: main;
  align-items: start;
  grid-template-areas: 'leftContent centerContent rightContent';

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    align
    grid-template-areas: ${(props: any) =>
    props.prioritizeCenterContent
      ? '"centerContent" "leftContent" "rightContent"'
      : '"leftContent" "centerContent" "rightContent"'};
  }
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

interface LogLayoutProps {
  prioritizeCenterContent?: boolean;
  centerTitle?: string;
}

const LogLayout: FunctionComponent<LogLayoutProps> = ({ children, prioritizeCenterContent = false }) => {
  return (
    <LayoutBox mx={'auto'} width={'100%'} maxWidth={1280} prioritizeCenterContent={prioritizeCenterContent}>
      <Box style={{ gridArea: 'centerContent' }}>
        <motion.div variants={container} initial="hidden" animate="show">
          <AddStravaWidget />
          {children}
        </motion.div>
      </Box>
      <Box sx={{ gridArea: 'leftContent' }}>
        <motion.div variants={item} initial="hidden" animate="show">
          <WeeklyMileage />
          <Box mb={3} />
          <ActivityTrends />
        </motion.div>
      </Box>
      <Box style={{ gridArea: 'rightContent' }}>
        <motion.div variants={item} initial="hidden" animate="show">
          <ShoeList />
        </motion.div>
      </Box>
    </LayoutBox>
  );
};

export default LogLayout;
