import { ApolloLink, createHttpLink } from '@apollo/client';
import { AUTH_TOKEN } from 'constant';

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });

const middlewareLink = new ApolloLink((operation, forward) => {
  const tokenValue = localStorage.getItem(AUTH_TOKEN);
  operation.setContext({
    headers: {
      Authorization: tokenValue ? `Bearer ${tokenValue}` : '',
    },
  });
  return forward(operation);
});

// authenticated httplink
export const httpLinkAuth = middlewareLink.concat(httpLink);
