import React, { FunctionComponent } from 'react';

interface MarketingIconProps {
  name: string;
  isDark: boolean;
}

const MarketingIcon: FunctionComponent<MarketingIconProps> = ({ name, isDark }) => {
  switch (name) {
    case 'Trends':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="12" fill={isDark ? '#76ddc6' : '#f2dca6'} />
          <path
            d="M18.3333 12H15.6667L13.6667 18L9.66667 6L7.66667 12H5"
            stroke={isDark ? 'hsl(230, 25%, 25%)' : '#ee5859'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'Journal':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="12" fill={isDark ? '#76ddc6' : '#f2dca6'} />
          <path
            d="M5 7H9C9.70724 7 10.3855 7.28095 10.8856 7.78105C11.3857 8.28115 11.6667 8.95942 11.6667 9.66667V19C11.6667 18.4696 11.456 17.9609 11.0809 17.5858C10.7058 17.2107 10.1971 17 9.66667 17H5V7Z"
            stroke={isDark ? 'hsl(230, 25%, 25%)' : '#ee5859'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.3332 7H14.3332C13.6259 7 12.9476 7.28095 12.4476 7.78105C11.9475 8.28115 11.6665 8.95942 11.6665 9.66667V19C11.6665 18.4696 11.8772 17.9609 12.2523 17.5858C12.6274 17.2107 13.1361 17 13.6665 17H18.3332V7Z"
            stroke={isDark ? 'hsl(230, 25%, 25%)' : '#ee5859'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    default:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="12" fill={isDark ? '#76ddc6' : '#f2dca6'} />
          <path
            d="M11 17.3333V10.6667"
            stroke={isDark ? 'hsl(230, 25%, 25%)' : '#ee5859'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 17.3333V6.66666"
            stroke={isDark ? 'hsl(230, 25%, 25%)' : '#ee5859'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 17.3333V14.6667"
            stroke={isDark ? 'hsl(230, 25%, 25%)' : '#ee5859'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};

export default MarketingIcon;
