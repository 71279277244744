/* eslint-disable react/prop-types */
import React, { FunctionComponent, useState } from 'react';
import { Text, Box, Flex, Button } from 'rebass';
import { toMiles } from 'helper/convert';
import { formatNumber } from 'helper/format';
import { DistanceUnit, Shoe as ShoeType } from 'generated/gql';
import { Edit, Moon } from 'react-feather';

interface ShoeProps {
  shoe: ShoeType;
  editShoe: () => void;
}

const Shoe: FunctionComponent<ShoeProps> = ({ shoe, editShoe }) => {
  const { name, brand, model, distance, isRetired } = shoe;
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Box mb={1} onMouseEnter={() => setShowEdit(true)} onMouseLeave={() => setShowEdit(false)}>
      <Flex alignItems="center">
        <Text fontSize={3} mb={0}>
          {name}
        </Text>
        {isRetired && (
          <Box ml={1} sx={{ color: 'primary' }} title="Retired">
            <Moon size={14} />
          </Box>
        )}
        {showEdit && (
          <Button sx={{ height: 'initial', color: 'primary' }} variant="tertiary" bg="transparent" onClick={editShoe}>
            <Edit size={14} />
          </Button>
        )}
      </Flex>
      <Flex justifyContent="space-between">
        <Text sx={{ color: 'muted' }} fontSize={1}>
          {brand} {model}
        </Text>
        <Text fontSize={2} sx={{ color: 'muted' }}>
          {formatNumber(toMiles(distance), DistanceUnit.Miles)}
        </Text>
      </Flex>
    </Box>
  );
};

export default Shoe;
