import React, { FunctionComponent, ReactNode } from 'react';
import { Box, Text } from 'rebass';
import { Input, Label, Textarea } from '@rebass/forms';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';

interface FormInputProps {
  type?: 'datetime-local' | 'number' | 'text' | 'textarea' | 'hidden';
  placeholder?: string;
  name: string;
  defaultValue?: string;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  incrementNumber?: string;
  showError?: boolean;
}

const FormInput: FunctionComponent<FormInputProps> = ({
  placeholder,
  name,
  defaultValue,
  label,
  errorMessage,
  incrementNumber,
  type = 'text',
  required = false,
  showError = true,
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const defaultErrorMessage = errorMessage || (required && `${label} is required`);
  let inputElement: ReactNode;
  switch (type) {
    case 'textarea':
      inputElement = (
        <Textarea
          {...register(name, { required: defaultErrorMessage })}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
          fontSize={3}
          wrap="hard"
          sx={{ resize: 'none' }}
          rows={3}
          cols={20}
        />
      );
      break;

    default:
      inputElement = (
        <Input
          {...register(name, { required: defaultErrorMessage, valueAsNumber: type === 'number' })}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
          step={incrementNumber}
        />
      );
      break;
  }

  const fieldError = get(errors, `${name}.message`);

  return (
    <Box>
      {label && (
        <Text as={Label} htmlFor={name} variant="label" mb={2}>
          {label}
        </Text>
      )}
      {inputElement}
      {fieldError && showError && (
        <Text mt={2} variant="error">
          {fieldError || defaultErrorMessage}
        </Text>
      )}
    </Box>
  );
};

export default FormInput;
