import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Box, Text } from 'rebass';
import { Input, Label } from '@rebass/forms';
import ShoeBrandDropdown from './ShoeBrandDropdown';
import gql from 'graphql-tag';
import { toMeters } from 'helper/convert';
import FormInput from './Forms/Inputs/FormInput';

const ADD_SHOE = gql`
  mutation addShoe($input: ShoeCreateInput!) {
    createShoe(input: $input) {
      id
    }
  }
`;

function ShoeForm() {
  const formMethods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const history = useHistory();
  const [addShoe] = useMutation(ADD_SHOE);

  const [brand, setShoeBrand] = useState({ value: '', label: '' });

  const onSubmit = (data: any, e: any) => {
    console.log('Submit event', e, data);
    data.isRetired = false;
    data.distance = toMeters(Number(data.distance));
    data.brand = !!brand ? brand.value : 'OTHER';
    addShoe({
      variables: {
        input: data,
      },
    })
      .then(() => {
        history.push('/activities');
      })
      .catch((err: Error) => {
        console.error('ERROR', { err }, err.message);
        // let errMessage = err.message;
        // if (err.message.includes('Failed to fetch')) {
        //   errMessage = 'Network issue. Try checking your internet connection';
        // }
        // setGlobalError(errMessage);
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={[2, 4, 4]}>
          <Text as={Label} variant="label" mb={2}>
            Shoe Brand
          </Text>
          <ShoeBrandDropdown selectedOption={brand} handleChange={(e: any) => setShoeBrand(e)} />
          <Text mt={2} variant="error">
            {errors && errors.brand && errors.brand.message}
          </Text>
        </Box>
        <Box mb={[2, 4, 4]}>
          <FormInput type="text" name="model" label="Shoe Model" placeholder="Clifton 5" required />
        </Box>
        <Box mb={[2, 4, 4]}>
          <FormInput type="text" name="name" label="Nickname" placeholder="My favorite shoes" />
        </Box>
        <Box mb={[2, 4, 4]}>
          <FormInput type="number" name="distance" label="Starting Distance" placeholder="0" />
        </Box>
        <Button as={Input} variant="primary" type="submit" />
      </form>
    </FormProvider>
  );
}

export default ShoeForm;
