import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastProvider } from 'react-toast-notifications';
import Loading from './components/Loading';
import AppRouter from 'components/AppRouter';
import client from 'services/apollo/client';

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <ApolloProvider client={client}>
        <ToastProvider autoDismiss>
          <AppRouter />
        </ToastProvider>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
