import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { ToastProvider } from 'react-toast-notifications';
import ActivityCreateForm from '../components/Forms/ActivityCreateForm';

const LayoutBox = styled(Box)`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr 0.8fr;
  grid-column-gap: 24px;
  grid-area: main;
  grid-template-areas: 'leftContent centerContent rightContent';

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-areas: 'leftContent' 'centerContent' 'rightContent';
  }
`;

function ActivityAddPage() {
  return (
    <ToastProvider autoDismiss>
      <LayoutBox px={[2, 0]}>
        <Flex style={{ gridArea: 'centerContent' }} flexDirection="column">
          <ActivityCreateForm />
        </Flex>
        <Box sx={{ gridArea: 'leftContent' }} />
        <Box style={{ gridArea: 'rightContent' }} />
      </LayoutBox>
    </ToastProvider>
  );
}

export default ActivityAddPage;
