import React, { FunctionComponent } from 'react';
import { Checkbox, Label } from '@rebass/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Text } from 'rebass';

interface CheckboxInputProps {
  name: string;
  label: string;
  errorMessage?: string;
  required?: boolean;
}

const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({ name, label, errorMessage, required = false }) => {
  const defaultErrorMessage = errorMessage || (required && `${label} is required`);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Box>
          <Label htmlFor={name} ml={2} sx={{ cursor: 'pointer', width: 'unset' }}>
            <Checkbox id={name} name={name} checked={value} onChange={onChange} />
            {label}
          </Label>
          {errors && (
            <Text mt={2} variant="error">
              {errors && errors[name] && (errors[name].message || defaultErrorMessage)}
            </Text>
          )}
        </Box>
      )}
    />
  );
};

export default CheckboxInput;
