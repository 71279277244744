import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import { isExpectedOAuthState } from '../services/strava';
import { useConnectStravaMutation } from '../generated/gql';
import { useToasts } from 'react-toast-notifications';

/**
 * This component is rendered on the route that we pass to Strava as the
 * OAuth 2.0 redirect_uri. Its job is to pass the user's auth information
 * along with the received authorization code to the back end for verification
 * and to obtain the final access token.
 */
const ConnectStrava = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [connectStrava] = useConnectStravaMutation();

  // Parse OAuth params from url
  const { searchParams } = new URL(window.location.href);

  useEffect(() => {
    const code = searchParams.get('code');
    const notNullCode = code !== null ? code : '';
    const scope = searchParams.get('scope');
    const notNullScope = scope !== null ? scope : '';
    const state = searchParams.get('state');
    const error = searchParams.get('error');

    if (error) {
      addToast(error, { appearance: 'error' });
      history.replace('/activities');
    } else if (state && isExpectedOAuthState(state)) {
      // on success, 1. update user context, 2. update Auth0? 3. redirect to log
      connectStrava({ variables: { code: notNullCode, scope: notNullScope } })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' });
        })
        .finally(() => {
          history.replace('/activities');
        });
    }
  }, [connectStrava, history, addToast, searchParams]);

  return <Loading />;
};

export default ConnectStrava;
