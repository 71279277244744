import { ApolloClient, ApolloLink } from '@apollo/client';
import cache from './cache';
import { httpLinkAuth } from './link';

const client = new ApolloClient({
  link: ApolloLink.from([httpLinkAuth]),
  cache,
  connectToDevTools: true,
});

export default client;
