import Dialog from '@reach/dialog';
import React, { FunctionComponent } from 'react';
import { X } from 'react-feather';
import { Box, Button } from 'rebass';
import '@reach/dialog/styles.css';

interface ModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({ children, ...props }) => {
  return (
    <Dialog
      aria-label="Dialog"
      {...props}
      style={{
        position: 'relative',
        maxWidth: 480,
        width: 'calc(100% - 32px)',
        padding: 0,
      }}
    >
      <Box sx={{ backgroundColor: 'background', padding: '2rem' }}>
        <Button
          sx={{ position: 'absolute', top: '8px', right: '8px', color: 'primary' }}
          variant="tertiary"
          bg="transparent"
          onClick={props.onDismiss}
        >
          <X size={16} />
        </Button>
        {children}
      </Box>
    </Dialog>
  );
};

export default Modal;
