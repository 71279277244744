import React, { useRef, useEffect } from 'react';
import polyline from '@mapbox/polyline';

const draw = (
  ctx: {
    strokeStyle: string;
    lineWidth: number;
    beginPath: () => void;
    lineTo: (arg0: number, arg1: number) => void;
    stroke: () => void;
  },
  arr: any[],
  width: number,
  height: number,
) => {
  let minX: number, minY: number, maxX: number, maxY: number;
  arr.forEach((p: number[], i: number) => {
    if (i === 0) {
      // if first point
      minX = maxX = p[1];
      minY = maxY = p[0];
    } else {
      minX = Math.min(p[1], minX);
      minY = Math.min(p[0], minY);
      maxX = Math.max(p[1], maxX);
      maxY = Math.max(p[0], maxY);
    }
  });
  // now get the map width and heigth in its local coords
  // @ts-ignore
  const mapWidth = maxX - minX;
  // @ts-ignore
  const mapHeight = maxY - minY;
  // @ts-ignore
  const mapCenterX = (maxX + minX) / 2;
  // @ts-ignore
  const mapCenterY = (maxY + minY) / 2;

  // to find the scale that will fit the canvas get the min scale to fit height or width
  const scale = Math.min(width / mapWidth, height / mapHeight);

  ctx.strokeStyle = '#e0a7ff';
  ctx.lineWidth = 2;

  // Now you can draw the map centered on the cavas
  ctx.beginPath();
  arr.forEach((p: number[]) => {
    ctx.lineTo((p[1] - mapCenterX) * scale + width / 2, (mapCenterY - p[0]) * scale + height / 2);
  });
  ctx.stroke();
};

const StravaLines = ({ width = 96, height = 96, polyLine }: { width: number; height: number; polyLine: string }) => {
  const canvasRef = useRef(null);

  const arr = polyline.decode(polyLine);

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore
    const context = canvas.getContext('2d');

    const render = () => {
      context.clearRect(0, 0, width, height);
      draw(context, arr, width, height);
    };
    render();
  }, [arr, height, width]);

  return <canvas style={{ width: '100%', height: '100%' }} ref={canvasRef} width={width} height={height} />;
};

export default StravaLines;
