import React, { useEffect, useState, FunctionComponent } from 'react';
import Loading from './Loading';
import { getUserInfo } from '../services/auth';
import { AUTH_TOKEN } from 'constant';
import { userInfo } from 'services/apollo/local-state';
import { User } from 'types/user';

/**
 * This component wraps the App with a user's auth info if present. It is
 * meant to give a seamless transition into the app when the user has previously
 * logged in and is starting up the app.
 */
const AuthContainer: FunctionComponent = ({ children }) => {
  // Block initial rendering of the app until we know the outcome of
  // the getUserInfo call below
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem(AUTH_TOKEN);
  useEffect(() => {
    if (token) {
      getUserInfo(token)
        .then((user) => {
          userInfo(user);
          setLoading(false);
        })
        .catch(() => {
          userInfo({} as User);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token]);

  return loading ? <Loading /> : <>{children}</>;
};

export default AuthContainer;
