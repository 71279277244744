import React, { FunctionComponent, useEffect } from 'react';
import Loading from 'components/Loading';
import { login } from 'services/auth';

const Login: FunctionComponent = () => {
  useEffect(() => {
    // For now, this component redirects the user to the Auth0 login page.
    // In the future, we may write our on UI to integrate with the Auth0
    // API, or implement a different auth solution entirely
    login();
  });
  return <Loading />;
};

export default Login;
