import React, { FunctionComponent } from 'react';
import { Box, Button, Flex, Heading } from 'rebass';
import { useFieldArray } from 'react-hook-form';
import IntervalInput from '../Inputs/IntervalInput';
import { IntervalType, WorkoutType } from 'generated/gql';
import { PlusCircle, Trash } from 'react-feather';
import styled from '@emotion/styled';

const IconButton = styled(Button)`
  padding: 8px;
  height: 42px;
  background-color: transparent;
`;

interface IntervalWorkoutInputsProps {
  name: string;
  workoutType: WorkoutType;
}

const IntervalWorkoutInputs: FunctionComponent<IntervalWorkoutInputsProps> = ({ name, workoutType }) => {
  const { fields, remove, insert } = useFieldArray({
    name,
  });

  // We visually separate warmup and cooldown from the rest of the intervals
  // Typing on react-hook-form is stupid
  const warmupIndex = fields.findIndex((item: any) => item.type === IntervalType.Warmup);
  const cooldownIndex = fields.findIndex((item: any) => item.type === IntervalType.Cooldown);
  const intervalFields = fields.filter((_, index) => index !== warmupIndex && index !== cooldownIndex);

  const intervalInputs = fields.map((item: any, index) => {
    if (item.type === IntervalType.Warmup || item.type === IntervalType.Cooldown) {
      // Skip warmup and cooldown -- they're rendered separately. We need to maintain
      // their indexes for the form state though
      return null;
    }
    return (
      <Flex key={item.id} alignItems="center" mb={1}>
        <IntervalInput
          name={`${name}[${index}]`}
          defaultValue={item}
          required
          showType={workoutType !== WorkoutType.Default}
        />
        {WorkoutType.Default && (
          <IconButton
            type="button"
            onClick={() => {
              return insert(index + 1, {
                // Alternate interval types
                type: item.type === IntervalType.Workout ? IntervalType.Default : IntervalType.Workout,
              });
            }}
            ml={3}
            sx={{ color: 'mutedText' }}
          >
            <PlusCircle />
          </IconButton>
        )}
        {WorkoutType.Default && intervalFields.length > 1 && (
          <IconButton
            type="button"
            onClick={() => {
              remove(index);
            }}
            ml={2}
            sx={{ color: 'mutedText' }}
          >
            <Trash />
          </IconButton>
        )}
        {/* TODO: duplicate set */}
      </Flex>
    );
  });

  return (
    <>
      {warmupIndex !== -1 ? (
        <Box mb={[1, 2, 3]}>
          <Heading mb={2}>Warmup</Heading>
          <IntervalInput name={`${name}[${warmupIndex}]`} defaultValue={fields[warmupIndex] as any} required />
        </Box>
      ) : null}
      <Box mb={[1, 2, 3]}>
        <Heading my={3}>Workout</Heading>
        {intervalInputs}
      </Box>
      {cooldownIndex !== -1 ? (
        <Box mb={[1, 2, 3]}>
          <Heading mb={2}>Cooldown</Heading>
          <IntervalInput name={`${name}[${cooldownIndex}]`} defaultValue={fields[cooldownIndex] as any} required />
        </Box>
      ) : null}
    </>
  );
};

export default IntervalWorkoutInputs;
